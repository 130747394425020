@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/animations';
@import 'sass/themes/color-themes';

.tags-wrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  .ui-menubutton__menu {
    .ui-menu__itemcontent {
      margin-right: 0;
    }
    .ui-button {
      min-width: 20px;
    }
  }
}
.tags-popup {
  .ui-popup__content__content {
    padding: 0;
    border: 0;
    @include themed() {
      background-color: t($seventh-combination);
      color: t($second-text-combination);
      box-shadow: 0px 1px 4px rgba(t($first-box-shadow-combination), 0.25);
    }
  }

  .tags-wrap {
    max-height: 400px;
    max-width: 180px;
    display: flex;
    flex-direction: column;
  }

  .tags-intro-title {
    font-size: 13px;
    font-weight: $regular;
    line-height: 16px;
    margin: 0 0 7px 0;
  }

  .tags-intro {
    padding: 12px;
    .ui-box {
      border-radius: 4px;
    }
    .ui-input__icon {
      width: auto;
    }
    .error-message {
      color: $red;
      font-size: 12px;
    }
    &-input {
      .ui-input__input {
        width: 100%;

        border-radius: 4px;
        @include themed() {
          color: t($second-text-combination);
          background-color: t($third-combination);
        }
      }
    }
    .ui-button {
      padding: 0;
      justify-content: flex-start;
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .tags-checkbox {
    display: flex;
    justify-content: space-between;
    padding: 3px 0 2px;
    .ui-checkbox__label {
      font-size: 12px;
      line-height: 16px;
      word-break: break-all;
    }
    .ui-button {
      height: auto;
      i {
        color: $red;
      }
    }
  }
  .tags-content {
    padding: 0 5px 0 12px;
    overflow-y: auto;
    max-height: 120px;
    font-size: 14px;
    .ui-checkbox {
      align-items: center;
      @include themed() {
        color: t($second-text-combination);
      }
      &[aria-disabled='true'] {
        opacity: 0.7;
      }
    }
  }

  .tags-actions {
    text-align: center;
    box-shadow: 0px 0.3px 0.9px rgba($black, 0.07), 0px 1.6px 3.6px rgba($black, 0.11);
    border-top: 1px solid $silver-dark;
    padding: 0;
    margin-top: 12px;
    .ui-button {
      width: 100%;
      height: auto;
      min-height: 32px;
      margin: 5px 0;
      .ui-box {
        margin-top: 2px;
      }
    }
    i {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .ui-button__content {
      font-size: 14px;
      font-weight: $regular;
      line-height: 19px;
      white-space: break-spaces;
      display: inline-block;
      word-break: break-word;
      @include themed() {
        color: t($second-text-combination);
      }
    }

    .tags-checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }

      .ui-checkbox {
        min-height: 22px;
        display: flex;
        align-items: center;
        padding: 0 0 0 2px;
        width: 100%;
      }

      .ui-checkbox__label {
        font-size: 12px;
        line-height: 16px;
        margin-left: 7px;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themed() {
          color: t($second-text-combination);
        }
      }

      .ui-button {
        min-height: 32px;
        min-width: 32px;

        i {
          color: $red;

          @include media-breakpoint-up(xl) {
            &:hover {
              color: $purple;
            }
          }
        }
      }
    }

    .tags-intro :not(.ui-button) .ui-box {
      margin-right: 0;
    }

    .tags-intro-input {
      .ui-input__input {
        font-size: 12px;
        line-height: 20px;
        border-bottom-color: $purple;
        margin-right: 0;
      }

      i {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .tags-button-delete {
      .ui-button__content,
      i {
        color: $red;
      }

      @include media-breakpoint-up(xl) {
        &:hover {
          .ui-button__content,
          i {
            color: $purple;
          }
        }
      }
    }
  }

  button.tags-popup-trigger {
    border-radius: 30px;
    padding: 3px 10px;
    height: auto;
    min-width: auto;
    align-items: baseline;

    &.tags-popup-trigger--icon {
      padding: 3px;
      min-width: 1px;

      .ui-box {
        margin: 0;
      }
    }

    @include themed() {
      background-color: t($ninth-combination);
      border: 1px solid t($sixth-border-combination);
    }

    &[disabled] {
      opacity: 0.7;
    }

    .ui-box {
      margin-right: 2px;
    }

    .ui-button__content {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-transform: lowercase;
      padding-right: 8px;
      @include themed() {
        color: t($fourth-text-combination);
      }
    }

    i {
      font-size: 7.5px;
      font-weight: 700;
      @include themed() {
        color: t($fourth-text-combination);
      }
    }
  }

  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .tag {
      border-radius: 30px;
      font-size: 12px;
      line-height: 16px;
      color: $purple;
      padding: 3px 10px;
      height: auto;
      box-shadow: none;
      max-height: 25px;
      cursor: pointer;

      @include themed() {
        border: 1px solid t($sixth-border-combination);
        background-color: t($thirteen-combination);
      }

      .tag-content {
        display: flex;
        overflow: hidden;
        max-width: 200px;

        .tag-name {
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ui-button {
          height: 20px;
          width: 20px;
          min-width: 20px;
          color: $purple;

          svg {
            height: 10px;
            width: 10px;
          }
        }
      }
    }
  }
}
