@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/animations';
@import 'sass/themes/color-themes';

.search-results-table {
  position: relative;
  clear: both;
  .popup-custom-wrap {
    position: absolute;
    right: 0px;
    top: 45px;
    z-index: 200;
  }
  .popup-custom {
    min-width: 212px;
    width: 100%;
    border-radius: 3px;
    padding: 4px;
    max-height: 256px;
    overflow: auto;
    @include themed() {
      background-color: t($sixth-combination);
      border: 1px solid t($sixth-border-combination);
    }
    box-shadow: rgba($black, 0.1) 0px 0.8rem 1rem -0.2rem;
    .ui-checkbox {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 5px 16px 7px 16px;
      &:hover {
        @include themed() {
          background-color: t($fourth-combination);
        }
      }
      .ui-box {
        margin-right: 0;
        margin-left: 10px;
        display: inline-block;
      }
      .ui-text {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  .button-table-options-wrap {
    position: absolute;
    right: 4px;
    top: 6px;
    .button-table-options {
      background: transparent;
      border: 0;
      box-shadow: none;
    }
  }
  @include media-breakpoint-up(xl) {
    .popup-custom {
      .ui-checkbox {
        .ui-text {
          &:hover {
            color: $purple;
          }
        }
      }
    }
  }
  .table-dialog-wrap {
    @include media-breakpoint-down(sm) {
      height: 100%;
      margin-top: 30px;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      @include themed() {
        background-color: t($first-combination);
        color: t($second-text-combination);
      }
      .table-dialog-icon {
        background-color: transparent;
        height: fit-content;
        border: none;
        i {
          font-size: 19px;
          @include themed() {
            color: t($second-text-combination);
          }
        }
      }
      .table-dialog-intro {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.12);
      }
      .table-dialog-title {
        margin: 0;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: -0.41px;
      }
      .table-dialog-description {
        margin: 22px 24px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
      }
      .table-dialog-list {
        margin-top: 0;
        padding-left: 16px;
        list-style: none;
      }
      .table-dialog-item {
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        padding: 13px 0;
        display: flex;
        position: relative;
        @include themed() {
          border-bottom: 1px solid t(sixth-border-combination);
        }
      }

      .table-dialog-item-num {
        display: inline-block;
        margin-right: 30px;
      }
      .table-dialog-item-options {
        margin-left: auto;
        .table-dialog-icons {
          display: flex;
          align-items: center;
          .table-dialog-icon {
            margin-right: 10px;
          }
          .button-dropdown {
            height: 18px;
            i {
              font-size: 17px;
            }
          }
        }
      }
      .table-dialog-popup {
        position: absolute;
        width: max-content;
        right: 4px;
        @include themed() {
          background-color: t(sixth-combination);
        }
        @include media-breakpoint-down(sm) {
          box-shadow: 0px 4px 4px 0px #f5f5f5;
          @include themed() {
            box-shadow: 0px 4px 4px 0px t($second-combination);
          }
          max-height: 270px;
          overflow: auto;
        }
        .table-dialog-popup-list {
          padding-left: 30px;
          padding-right: 10px;
          li {
            margin-top: 7px;
          }
          @include media-breakpoint-down(sm) {
            padding: 3px 10px 10px 30px;
          }
        }
      }
    }
  }
  .table-dialog-overlay {
    @include media-breakpoint-down(sm) {
      height: 100vh;
      top: -46px;
    }
  }
}

.table-results {
  &.table--without-popup {
    .ms-DetailsHeader-cell {
      &:last-child {
        position: unset;
        width: 100% !important;
      }
    }
  }
  .ms-DetailsHeader {
    padding-top: 0;
    padding-right: 40px;
    width: calc(100% - 40px) !important;
    min-width: calc(100% - 40px);
    @include themed() {
      background-color: t($ninth-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
    .ms-DetailsHeader-cellName {
      @include themed() {
        color: t($fifth-text-combination);
      }
      @include media-breakpoint-down(sm) {
        font-size: 12px;
        font-weight: 700;
      }
    }
    .ms-Icon {
      @include themed() {
        color: t($fifth-text-combination);
      }
    }
  }
  .ms-DetailsHeader-cellIsCheck {
    padding: 4px;
  }
  .ms-Check {
    border: 1px solid $dark;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    @include themed() {
      border: 1px solid t($second-text-combination);
    }
    &.is-checked {
      &::before {
        background: $purple;
      }
      .ms-Icon {
        color: $white;
      }
    }
    &::before {
      content: '';
      border-radius: 2px;
      position: absolute;
      left: -1px;
      right: 0;
      top: -1px;
      bottom: 0;
      width: 18px;
      height: 18px;
      background: transparent;
    }
    i[data-icon-name='CircleRing'] {
      display: none;
    }
    i[data-icon-name='StatusCircleCheckmark'] {
      left: 0;
    }
  }
  .ms-DetailsRow-cellCheck {
    width: 62px;
    height: 62px;
  }
  .ms-DetailsHeader-cell {
    .ms-Icon[data-icon-name='GripperBarVertical'] {
      display: none;
    }
    &:hover {
      @include themed() {
        background-color: t($third-hover-combination);
      }
      .ms-DetailsHeader-cell {
        .ms-Icon[data-icon-name='GripperBarVertical'] {
          display: none;
        }
      }
    }
    &:last-child {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 40px;
      width: 40px !important;
      padding: 0 10px;
      cursor: pointer;
      @include themed() {
        border-left: 1px solid t($sixth-border-combination);
      }
      .ms-DetailsHeader-cellName {
        i {
          @include themed() {
            color: t($third-icon-combination);
          }
          color: $dark;
          font-weight: $semi-bold;
        }
      }
    }
  }
  .ms-DetailsHeader-cellSizer {
    line-height: unset;
    top: 50%;
    height: 50%;
    left: 0;
    transform: translateY(-75%);
    &:after {
      opacity: 1;
      @include themed() {
        background: transparent;
      }
    }
  }
  .ms-DetailsList {
    > div {
      width: fit-content;
      min-width: 100%;
      padding-bottom: 90px;
    }
  }
  .ms-FocusZone {
    width: 100%;
  }
  .ms-DetailsRow-fields {
    width: 100%;
    padding: 10px 0 5px 0;
  }
  .ms-DetailsRow {
    min-height: 57px;
    cursor: pointer;
    @include themed() {
      background-color: t($ninth-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
  }
  .ms-DetailsRow-cell {
    font-size: 12px;
    line-height: 16px;
    @include themed() {
      color: t($nine-text-combination);
    }
    &:first-child {
      font-size: 14px;
      line-height: 20px;
      @include themed() {
        color: t($fifth-text-combination);
      }
    }
    &:last-child {
      width: 40px !important;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &.saved-search-table {
    overflow: hidden;
    .ms-DetailsRow-cell {
      &:last-child {
        position: static;
        width: auto !important;
      }
    }
    .row-header-wrap {
      padding: 19px;
    }
    .ms-Checkbox-label {
      font-size: 11px;
    }
  }
  .row-header-wrap {
    padding: 15px;
    .ui-checkbox {
      padding: 6px;
    }
    .ui-label {
      background-color: $light-blue;
      color: #346c6d;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
  }
  .is-row-header {
    padding: 10px 0 0 7px;
    @include themed() {
      color: t($fifth-text-combination);
    }
  }

  @include media-breakpoint-down(lg) {
    .ms-DetailsRow-cell {
      max-width: 190px;
    }
    .ms-DetailsHeader-cell {
      max-width: 190px;
    }
  }
  @include media-breakpoint-down(md) {
    .ms-DetailsRow-cell {
      max-width: 180px;
    }
    .ms-DetailsHeader-cell {
      max-width: 180px;
    }
  }
  @include media-breakpoint-down(sm) {
    .ms-DetailsRow-cell {
      max-width: 160px;
    }
    .ms-DetailsHeader-cell {
      max-width: 160px;
    }
  }

  .table-dialog-overlay {
    position: fixed;
    min-height: calc(100% - 70px);
    z-index: 60000000;
    visibility: visible;
    opacity: 1;
    animation: custom-table-popup 0.7s ease;
  }

  .table-dialog-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    min-height: calc(100% - 70px);
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-top: $silver-dark;
    z-index: 500;
    visibility: visible;
    opacity: 1;
    animation: custom-table-popup 1s ease;
    @include themed() {
      background: t($ninth-combination);
    }
    .table-dialog-intro {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      @include themed() {
        border-bottom: 1px solid t($fifth-border-combination);
      }
    }
    .table-dialog-title {
      font-size: 17px;
      line-height: 22px;
      margin: 0;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .table-dialog-item-name {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .table-dialog-icon {
      outline: none;
      border: 0;
      background: transparent;
      i {
        @include themed() {
          color: t($second-icon-combination);
        }
      }
      .icon-clear {
        font-size: 14px;
      }
      .icon-check {
        font-size: 22px;
      }
      .icon-flick {
        height: 10px;
      }
    }
    .table-dialog-content {
      padding: 0 15px;
    }
    .table-dialog-description {
      font-size: 16px;
      line-height: 20px;
      @include themed() {
        color: t($second-icon-combination);
      }
    }
    .table-dialog-list {
      padding-left: 10px;
      list-style: none;
    }
    .table-dialog-list--nested {
      padding-left: 0;
    }
    .table-dialog-item {
      position: relative;
      padding: 5px 0;
      @include themed() {
        border-bottom: 1px solid t($fifth-border-combination);
      }
    }
    .table-dialog-item-num {
      margin-right: 25px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .table-dialog-item-options {
      position: absolute;
      right: 0;
      top: 5px;
    }
    .table-dialog-icons {
      display: flex;
      align-items: center;
    }
    .table-dialog-item--nested {
      border-bottom: none;
    }
    .button-dropdown {
      margin-right: 15px;
    }
    .table-dialog-popup {
      position: absolute;
      right: 20px;
      top: 35px;
      min-width: 120px;
      border-radius: 4px;
      box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
      @include themed() {
        background: t($fifth-combination);
      }
      z-index: 100;
      .table-dialog-popup-list {
        list-style: none;
        padding-left: 0;
      }
      .table-dialog-popup-item {
        padding: 5px 10px;
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
}
.saved-search-table {
  .table-results .ms-DetailsList > div {
    width: auto;
  }
  .ms-DetailsList {
    width: max-content;
  }
}
