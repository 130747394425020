

// Custom table popup
@keyframes custom-bottom-popup {
    0% {
        min-height: 0;
        opacity: 0;
        visibility: hidden;
    }
    50% {
        min-height: 50px;
        opacity: 0;
        visibility: hidden;
    }
    100% {
        min-height: 1px;
        visibility: visible;
        opacity: 1;
    }
}

@keyframes smooth-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}