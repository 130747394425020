@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.audience-table {
  .ui-avatar {
    .ui-label {
      background-color: $light-blue;
      color: #346c6d;
    }
  }

  .ms-DetailsRow-cell {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    min-height: 38px;
    &.purple {
      color: $purple;
    }
    .ui-button {
      min-width: auto;
    }
    .row-header-wrap {
      padding-top: 10px;
      padding-left: 5px;
    }
  }
}
.audience-table-tab {
  padding: 0 19px 0 16px;
  .paging-buttons {
    .ui-buttons {
      button {
        min-width: 1.5rem;
      }
    }
  }
  .ui-avatar {
    .ui-label {
      background-color: $light-blue;
      color: #346c6d;
    }
  }
  .ui-icon {
    svg {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .action-bar-buttons {
    width: 100%;
    flex-wrap: wrap;
    .action-bar-button:first-of-type {
      margin-left: 0;
      i {
        font-size: 9px;
      }
    }
  }
  .action-bar-input {
    display: flex;
    margin-left: auto;
    align-items: center;
  }
  .ms-DetailsHeader {
    @include themed() {
      background-color: t($sixth-combination);
      color: t($second-text-combination);
    }
  }
  .ms-Dropdown-title {
    @include themed() {
      background-color: t($sixth-combination);
      color: t($second-text-combination);
    }
  }
  .ms-Dropdown-caretDownWrapper {
    i {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .ms-Check {
    &::before {
      @include themed() {
        background-color: t($sixth-combination);
      }
    }
  }
  .ms-DetailsHeader-cell {
    &:hover {
      @include themed() {
        background-color: t($fourth-combination);
        color: t($second-text-combination);
      }
    }
  }
  .ms-DetailsRow {
    width: 100%;
    padding-left: 4.5px;
    @include themed() {
      background-color: t($fourth-combination);
    }
  }
  .row-header-wrap {
    padding-top: 5px;
  }
  .ui-button {
    min-width: fit-content;
    height: auto;
  }
  .ms-DetailsRow-cell {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    min-height: 38px;
    @include themed() {
      color: t($second-text-combination);
    }
    &.purple {
      color: $purple;
    }
    .ui-button {
      min-width: auto;
    }
  }
  .ms-DetailsHeader {
    .ms-DetailsHeader-cellName {
      font-size: 12px;
      font-weight: 300;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .ms-DetailsHeader-check {
      opacity: 1;
      width: 33px;
    }
    .ms-Check {
      border: 1px solid $dark-light;
      border-radius: 4px;
      &.is-checked {
        background-color: $purple;
        border-color: transparent;
        &::before {
          background: transparent;
        }
      }
    }
    .ms-Check-circle {
      display: none;
    }
  }
}
.ms-Dropdown-optionText {
  @include themed() {
    color: t($second-text-combination);
  }
}

.audience-table-checkbox {
  width: 28px;
}

.audience-button {
  max-height: 18px;
}
