@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.shimmer-cards-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  justify-content: space-between;

  .ui-skeleton__shape {
    max-width: 310px;
    width: 100%;
    border-radius: 4px;
    margin: 5px;
  }
}

.shimmer-search-wrap {
  padding: 20px;
  border-radius: 4px;
  @include themed() {
    background-color: t($sixth-combination);
  }

  .shimmer-search-intro {
    margin-bottom: 15px;

    .ui-skeleton__line {
      max-width: 260px;
    }
  }

  .shimmer-search {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .shimmer-search-description {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .ui-skeleton__line:first-child {
      max-width: 60px;
    }

    .ui-skeleton__line:last-child {
      max-width: 90px;
    }
  }

  .shimmer-search-content {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .ui-skeleton__avatar {
      margin-right: 20px;
    }
  }
}

.shimmer-table {
  .shimmer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .shimmer-avatar {
      margin-right: 15px;
    }

    .shimmer-line {
      max-width: 20%;
      width: 100%;
    }

    .ui-skeleton__text {
      max-width: 120px;
      display: block;
      max-height: 10px;
    }
  }
}

.shimmer-panel-wrap {
  padding: 0 20px;
  @include themed() {
    background-color: t($sixth-combination);
  }
  .shimmer-panel-intro {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
  }

  .shimmer-panel-intro-avatar {
    margin-right: 20px;
  }

  .shimmer-panel-intro-content {
    width: 100%;

    .ui-skeleton__line:first-child {
      margin-bottom: 10px;
      max-width: 200px;
    }

    .ui-skeleton__avatar {
      margin-top: 10px;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .shimmer-panel-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
  }

  .shimmer-panel-accordion-intro {
    margin-bottom: 20px;
  }

  .shimmer-panel-accordion-charts {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 30px;
  }

  .shimmer-panel-accordion-charts-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .shimmer-panel-accordion-chart {
    .ui-skeleton__avatar {
      margin-bottom: 10px;
    }
  }

  .shimmer-panel-accordion-info-line {
    display: flex;
    gap: 50px;
    margin-bottom: 20px;
    :first-child {
      max-width: 20%;
    }
  }
}
