@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.navigation {
  position: fixed;
  height: 100%;
  width: 200px;
  margin-left: 20px;
  padding-top: 30px;
  box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.15);
  @include themed() {
    background-color: t($fifth-combination);
  }
  transition: width $dur-small cubic-bezier(0.37, 0.36, 0.63, 0.55);
  z-index: 2500;
  .navigation-items {
    .ui-menu {
      padding: 0;
      width: 100%;
      @include themed() {
        background-color: t($fifth-combination);
      }
    }
    .ui-menu__item {
      padding: 8px 11px;
      border: 0;
      border-radius: 3px;
      &:before {
        content: '';
        position: absolute;
        left: -11px;
        top: -1px;
        width: 2px;
        height: 108%;
        background-color: $transparent;
      }
      &.active {
        .custom-item-text {
          font-weight: 600;
        }
        &::before {
          background-color: $purple;
          left: -5px;
        }
        @include themed() {
          background-color: t($sixth-combination);
        }
      }
    }
    .ui-menu__itemcontent {
      padding: 0;
      margin: 0;
      display: inline-block;
      width: 100%;
    }
    .ui-menu__itemwrapper {
      margin: 1px 14px 1px 0;
      border: 0;
      border-radius: 5px;
      &:hover {
        @include themed() {
          background-color: t($first-combination);
        }
      }
      &::before {
        display: none;
      }
      &:nth-of-type(6) {
        display: none;
      }
    }
    .custom-item {
      display: flex;
      align-items: center;
      width: fit-content;
    }
    .custom-item-icon {
      margin-right: 10px;
      i {
        font-size: 16px;
        transition: $dur-small $ease;
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }
    .custom-item-text {
      font-size: 14px;
      font-weight: $regular;
      line-height: 20px;
      transition: $dur-small $ease;

      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .navigation-items {
      .ui-menu__item {
        &:hover {
          &::before {
            display: none;
          }
          @include themed() {
            background-color: t($sixth-combination);
          }
          .custom-item-text {
            font-weight: $semi-bold;
            color: $purple;
          }
          .custom-item-icon i {
            color: $purple;
          }
        }
        &.active {
          &:hover {
            &::before {
              display: block;
              background-color: $purple;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    position: absolute;
    width: 100%;
    height: auto;
    box-shadow: none;
    margin-top: 90px;
    margin-left: 0;
    padding-top: 0;
    .navigation-items {
      &.my-activities-nav {
        margin-top: -90px;
      }
      .ui-menu__itemwrapper {
        margin: 1px 7px 0;
        height: 100%;
        &:nth-of-type(6) {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          min-width: 50px;
        }
        &:nth-of-type(5) {
          display: none;
        }
      }
      .ui-menu {
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
        height: 100%;
        @include themed() {
          border-bottom: 1px solid t($second-combination);
        }
        .ui-menu {
          position: absolute;
          transform: translate3d(-30px, 50px, 0) !important;
          background-color: $white;
          width: 70px;
          border: 0;
          margin: 0 auto;
          box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
          border-radius: 4px;
        }
      }
      .ui-menu__itemwrapper {
        &:hover {
          background-color: transparent !important;
        }
      }
      .custom-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .custom-item-text {
        font-size: 11px;
        line-height: 16px;
      }
      .custom-item-icon {
        margin-right: 0;
        i {
          font-size: 20px;
        }
      }
      .ui-menu__item {
        padding: 5px 9px;
        text-align: center;
        &.active {
          background-color: transparent !important;
          &::before {
            left: -2.5px;
            top: unset;
            bottom: 0;
            width: 106%;
            height: 2.5px;
          }
        }
      }
      .ui-menu__itemindicator {
        display: none;
      }
      .ui-menu__itemcontent {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .navigation-items {
      .ui-menu__item {
        padding: 5px;
        text-align: center;
      }
      .custom-item-text {
        font-size: 9px;
      }
    }
  }
}

.navigation-menu-dropdown {
  position: absolute;
  bottom: 17px;
  left: 0;
  width: 100%;
  padding: 0 15px;
  transition: $dur $ease;
  .ui-menubutton {
    width: 100%;
  }
  .ui-menu__item {
    min-width: 170px;
    display: flex;
  }
  .ui-menu__itemicon {
    margin-right: 4px;
  }
  .ui-menu__itemindicator {
    margin-left: auto;
  }
  .ui-button {
    width: 100%;
    transition: $dur $ease;
    .ui-box {
      margin-right: 2px;
      margin-top: 2px;
      margin-left: -3px;
    }
    i {
      font-size: 10px;
    }
  }
  .ui-button__content {
    font-size: 14px;
    font-weight: $regular;
  }
  .ui-menu__itemwrapper {
    font-size: 14px;
    &:hover {
      @include themed() {
        background: t($third-combination);
      }
    }
  }
  .ui-menu__itemcontent {
    button {
      font-size: 14px;
      border: 0;
      padding: 3px 0;
      background: transparent;
      box-shadow: none;
      cursor: pointer;
      @include themed() {
        color: t($second-text-combination);
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

// Menu Button

.button-menu-wrap {
  padding: 11px 15px 11px 5px;
  background-color: transparent;
  .button-menu {
    width: 24px;
    height: 24px;
    padding: 4px;
    border: 0;
    outline: 0;
    cursor: pointer;
    box-shadow: none;
    @include themed() {
      background-color: t($fifth-combination);
    }
    svg {
      @include themed() {
        color: t($second-icon-combination);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
