@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';

.banner {
  position: relative;
  width: 100%;
  min-height: 260px;
  background: rgba($purple, 0.4);
  @include media-breakpoint-between(md, lg) {
    min-height: 260px;
  }
  @include media-breakpoint-down(sm) {
    min-height: 160px;
    margin-top: 57px;
  }
}

.banner--lists {
  .banner-image {
    width: 120px;
  }
  .banner-image-wrap {
    top: 40px;
  }
}

.banner-image-wrap {
  position: absolute;
  left: 50%;
  top: 34px;
  transform: translateX(-50%);
  @include media-breakpoint-between(md, lg) {
    top: 30px;
  }
  @include media-breakpoint-down(sm) {
    top: 18px;
  }
}

.banner-image {
  min-width: 106px;
  max-width: 150px;
  width: auto;
  transition: $dur-small cubic-bezier(0.59, 0.31, 0.12, 0.99);
  @include media-breakpoint-down(lg) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    max-width: 90px;
    min-width: auto;
  }
}
