@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.accordion-toolbar {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding: 7px;
  @include themed() {
    background-color: t($eleventh-combination);
  }
  .ui-popup__content__content {
    @include themed() {
      border: 1px solid t($thirteen-combination);
    }
    ul {
      padding: 8px 0;
      border: none;
      li {
        padding: 6px 0;
        .ui-menu__item {
          display: flex;
          justify-content: space-between;
          line-height: initial;
          &.menu-item__active {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.accordion-toolbar-button {
  .ui-button {
    border: 0;
    box-shadow: none;
    background: transparent;
    padding: 0 7px;
    min-width: 60px;

    &:hover {
      background: transparent;

      .ui-button__content {
        color: $purple;
      }

      .ui-icon {
        svg {
          fill: $purple;
        }
      }
    }
  }

  .ui-button__content {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    @include themed() {
      color: t($seven-text-combination);
    }
  }

  .ui-box {
    margin-right: 2px;
  }

  .ui-icon {
    svg {
      width: 8px;
      @include themed() {
        fill: t($seven-text-combination);
      }
    }
  }
}

.accordion-toolbar-search {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(sm) {
    width: 100%;
    justify-content: space-between;
  }
  .ui-icon {
    svg {
      cursor: pointer;
    }
  }
  .accordion-toolbar-items {
    font-size: 12px;
    line-height: 20px;
    margin-right: 10px;
    @include themed() {
      color: t($seven-text-combination);
    }
  }

  .ui-input__input {
    font-size: 12px;
    line-height: 20px;
    &::placeholder {
      @include themed() {
        color: t($seven-text-combination);
        opacity: 0.8;
      }
    }
    @include themed() {
      background-color: t($fifth-combination);
      color: t($seven-text-combination);
    }
  }

  .ui-input__icon {
    svg {
      width: 14px;
      @include themed() {
        fill: t($seven-text-combination);
      }
    }
  }
}

.toolbar-filter {
  .ui-button__content {
    color: $purple;
  }

  svg {
    fill: $purple;
  }
}
