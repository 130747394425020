@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';
@import 'sass/abstracts/mixins';

.panel-saved-searches {
  &.overflow {
    .ms-Panel-scrollableContent {
      position: relative;
      margin-right: -17px;
      margin-bottom: -17px;
      padding-bottom: 24px;
    }
  }
  .ms-Panel-main {
    width: 380px;
    box-shadow: 0px 4px 8px rgba($black, 0.16);
    @include themed() {
      background-color: t($fifth-combination);
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      .saved-searches-search-input.expanded .ui-input__input {
        width: 180px;
      }
    }
  }
  &.ignore-animation {
    .ms-Panel-main {
      animation: none;
    }
  }
  .saved-searches-intro {
    display: block;
    width: 101%;
    padding: 0 0 10px 0;
    margin-bottom: 20px;
    background: $transparent;
  }
  .ms-Panel-contentInner {
    overflow: hidden;
  }
  .ms-Panel-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ms-Panel-commands {
    position: unset;
    @include themed() {
      background-color: t($fifth-combination);
    }
  }
  .ms-Panel-navigation {
    margin-bottom: 20px;
  }
  .ms-Panel-headerText {
    font-size: 20px;
    @include themed() {
      color: t($second-text-combination);
    }
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
  .saved-searches-inputs {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ms-Panel-closeButton {
    margin-right: 18px;
    @include themed() {
      background-color: t($fifth-combination);
    }
    &:hover {
      @include themed() {
        background-color: t($first-combination);
      }

      i {
        color: $purple;
      }
    }
    i {
      @include themed() {
        color: t($second-icon-combination);
      }
    }
  }
  .saved-searches-filter {
    .filter-button {
      min-width: 68px;
      max-width: 100%;
      padding: 0;
    }
  }
  .saved-searches-search-input {
    width: 100%;
    .ui-input__input {
      max-width: 100%;
    }
  }
  .card-panel-background-wrap {
    position: absolute;
    left: 0;
    top: 130px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 18px;
    width: 100%;
    display: none;
  }
  .card-panel-background {
    width: 100%;
    height: 107px;
    margin: 0 6px 16px 6px;
    display: block;
    border-radius: 4px;
    background-color: $gray-blue-light;
    z-index: 120;
    box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
  }
  @include media-breakpoint-down(lg) {
    &.overfow {
      .ms-Panel-scrollableContent {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .filter-button {
      min-width: 50px;
    }
  }
  .shimmer-cards-wrap {
    padding: 0;
    .ui-skeleton__shape {
      width: 100%;
      border-radius: 4px;
      margin: 0 0 15px 0;
      min-height: 108px;
      max-width: unset;
    }
  }
}

.panel-card-wrap {
  position: relative;
  z-index: 180;
  .panel-card-container {
    position: relative;
  }
  .card-text__wrap {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    span:not(.card-text) {
      margin-right: 3px;
    }
    @include themed() {
      color: t($second-text-combination);
    }
    @include media-breakpoint-down(sm) {
      max-width: 95%;
    }
  }
  .panel-card {
    margin-bottom: 15px;
    position: relative;
    min-height: 108px;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    word-break: break-all;
    box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
    @include themed() {
      background-color: t($sixth-combination);
    }
    &.active {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
        width: 100%;
        background-color: $light-green;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
    .panel-card-body {
      max-width: 82%;
    }
    .card-text {
      margin: 0;
      max-width: 83%;
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      font-weight: $bold;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .card-avatars {
      position: absolute;
      left: 15px;
      bottom: 8px;
      @for $i from 1 through $reapet {
        .ui-avatar:nth-child(#{length($colors)}n + #{$i}) {
          .ui-label {
            width: 30px;
            font-weight: 700;
            background: nth($colors, random(length($colors)));
            color: darken(nth($colors, random(length($colors))), 70%);
            text-transform: uppercase;
          }
        }
      }
      .ui-avatar {
        position: relative;
        margin-left: -5px;
        &::after {
          content: '';
          position: absolute;
          left: -1px;
          top: -1px;
          width: 25px;
          height: 25px;
          background-color: transparent;
          border-radius: 50%;
        }
      }
    }
  }
  .card-icons {
    position: absolute;
    right: 0;
    top: 2px;
    display: none;
    align-items: center;

    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
  }
  .panel-card:hover .card-icons {
    display: flex;
  }
  .card-input {
    position: relative;

    .ui-input {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      z-index: 100;
    }
    .ui-input__input {
      font-size: 13px;
      line-height: 16px;
      width: 100%;
      border-radius: 3px;
      padding: 12px 5px;
      border-bottom: 3px solid $purple;
      background-color: $gray-lighten-2;
      color: $dark;
      min-height: 50px;

      div {
        max-width: 80%;
        @include text-truncate;
      }
    }

    .card-input-actions {
      position: absolute;
      right: -15px;
      top: 55px;
      display: inherit;

      i {
        font-size: 18px;
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .panel-card {
      &:hover {
        @include themed() {
          background-color: t($sixth-combination);
        }
        box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
      }
    }
  }
}
