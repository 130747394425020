@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.search-results-wrap {
  padding: 30px 0;
  .action-bar-wrap {
    background-color: transparent;
    padding: 0;
  }
  .search-wrap {
    padding: 0;
    max-width: 400px;
    width: 100%;
    margin: unset;
    transition: none;
    &.search-popup--active {
      position: absolute;
      left: 0;
      top: 165px;
      max-width: 100%;
      .search-help {
        display: none;
      }
      .search-popup {
        z-index: 120;
      }
      .search-input {
        position: absolute;
        top: -178px;
        left: 0;
      }
      div.dropdown-show-all-results-wrap {
        margin-bottom: 5px;
        z-index: 10;
      }
    }
  }
  .search-help {
    display: none;
  }
  .search-input {
    position: unset;
    left: unset;
    bottom: unset;
    transform: unset;
    max-width: 400px;
    padding: 0;
    .ui-input__input {
      padding: 8px 13px;
      font-size: 14px;
    }
    .icon-cancel {
      cursor: pointer;
    }
    .ui-input__icon i {
      font-size: 14px;
      right: 0;
    }
    .ui-icon {
      right: 0;
    }
  }
  .dropdown {
    padding: 0;
    top: 40px;
  }
  .empty-search-image {
    max-width: 120px;
  }
  .button-filter {
    box-shadow: none;
    border: 0;
    background-color: $transparent;
    height: auto;
    margin-bottom: 12px;
    &:active {
      background-color: $transparent;
    }
    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
    .ui-button__content {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .ui-box {
      margin: 0;
    }
    .ui-button__content {
      margin-left: 5px;
    }
    &:focus {
      border: $transparent;
    }
  }
  @include media-breakpoint-up(xl) {
    .button-filter {
      &:hover {
        .ui-button__content {
          color: $purple;
        }

        svg {
          fill: $purple;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .search-wrap {
      max-width: 250px;
    }
    .search-input {
      max-width: 250px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 56px 0 20px 0;
    &.list-search {
      .filter-input-wrap {
        right: 10px;
      }
      .search-results-more-wrap .button-search {
        right: 36px;
      }
    }
    .searched-text {
      display: none;
    }
    .search-wrap {
      max-width: 150px;
      position: absolute;
      right: 87px;
      top: 8px;
    }
    .search-input {
      max-width: 150px;
    }
    .search-input {
      .ui-input__input {
        border-radius: none;
        box-shadow: none;
      }
    }
    .button-filter {
      .ui-button__content {
        display: none;
      }
    }
    .search-popup-segments {
      margin-top: 69px;
    }
  }
}

.search-results-intro {
  position: relative;
  padding: 0 20px;
  z-index: 280;
  @include media-breakpoint-down(sm) {
    display: flex;
    align-items: center;
    padding: 15px 13px 15px 16px;
    z-index: 0;
    &:has(.search-popup--active) {
      z-index: 10000;
    }

    @include themed() {
      background-color: t($fourth-combination);
    }

    .search-wrap {
      right: 62px;
      .search-popup {
        top: -219px;
      }
      .ui-input__input {
        padding-right: 32px;
        font-size: 16px;
        &::placeholder {
          font-size: 12px;
        }
        @include themed() {
          background-color: t($fifth-combination);
        }
      }
      &.search-popup--active {
        .ui-input__input {
          @include themed() {
            background-color: t($eighth-combination);
          }
        }

        .dropdown-segment {
          padding-top: 27px;
        }
      }
    }
  }
}

.search-results-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .add-emailing-button {
    button {
      padding: 0 10px;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin-bottom: 0;
    align-items: baseline;
  }
}

.add-emailing-button {
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
  i {
    font-size: 13px;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
}
.search-results-content {
  position: relative;
  padding: 20px;
  &.template {
    padding: 0;
    margin-left: auto;
    .filter-input-wrap,
    .filter-pills-wrap {
      margin: 0;
    }
  }
  .action-bar-selected {
    margin-right: 10px;
  }
  .action-bar-button {
    margin-left: 2px;
    .ui-button {
      font-size: 11px;
      letter-spacing: 0.06px;
      display: flex;
      align-items: center;
    }
    .ui-box {
      i {
        font-size: 18px;
      }
    }
    &.export-icon {
      i {
        font-size: 20px;
      }
    }
    .tags-actions {
      .ui-button__content {
        font-size: 14px;
      }
    }
  }
  .filter-input-wrap {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-start;
  }
  .filter-pills-wrap {
    position: relative;
    margin-right: 20px;
    flex: auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }
  .filter-pill {
    max-width: 180px;
    width: auto;
    height: 25px;
    background: $white;
    border: 1px solid $gray-lighten-4;
    margin: auto 0.25rem;
    cursor: pointer;
    margin-bottom: 3px;
    @include themed() {
      background-color: t($ninth-combination);
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .filter-pill-text {
      max-width: 150px;
      padding: 2px 0px 3px 12px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      display: block;
      @include text-truncate;
    }
    svg {
      width: 10px;
      height: 10px;
      @include themed() {
        fill: t($second-text-combination);
      }
    }
    .ui-button {
      min-width: 20px;
      height: 17px;
      padding-top: 1px;
    }
    .ui-pillcontent {
      padding: 0 8px 0 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      @include themed() {
        color: t($fourth-text-combination);
      }
    }
    .filter-pill-field-name {
      text-transform: capitalize;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .filter-pills-number-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    background: $white;
    border-radius: 50%;
    border: 1px solid $gray-lighten;
    font-size: 13px;
    .filter-pills-number {
      @include align(absolute, xy);
      color: $purple;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 40px 20px 20px 20px;
    .filter-pill {
      max-width: 130px;
      .filter-pill-text {
        max-width: 105px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
    .filter-input-wrap {
      position: absolute;
      top: -31px;
      right: 30px;
    }
  }
}

.filter-pills-tooltip-wrap {
  .filter-pills-tooltip-field-name {
    display: inline-block;
    margin-right: 5px;
    text-transform: capitalize;
  }
}

.filter-pills-tooltip {
  display: block;
}

.search-results-component {
  margin-bottom: 25px;
  display: flex;
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}

.search-results-text {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  &.mo-search-results-text {
    margin-bottom: 12px;
  }
  @include themed() {
    color: t($second-text-combination);
  }
  .text-bold {
    font-weight: $semi-bold;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 10px 0 0;
    font-size: 14px;
    &.mo-search-results-text {
      margin-bottom: 0;
    }
  }
}

.search-result-actions {
  display: flex;
}

.search-results-buttons {
  display: flex;
  margin-left: 20px;
  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
  .add-emailing-button {
    button {
      padding: 0 10px;
    }
  }
  .search-results-button {
    background-color: transparent;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    font-weight: 600;
    font-size: 14px;
    transition: $dur-small $ease;
    @include themed() {
      color: t($fourth-text-combination);
    }
    &:hover {
      opacity: 0.7;
    }
    i {
      padding-right: 10px;
      font-size: 16px;
      @include themed() {
        color: t($fourth-text-combination);
      }
    }
  }
  .button-save-search {
    margin-right: 15px;
  }

  .search-results-button {
    &:hover {
      background-color: transparent;
      color: $purple;
      i {
        color: $purple;
      }
    }
  }
}

.search-results-more-wrap {
  .button-more,
  .button-search {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    padding-right: 10px;
    padding-left: 10px;
    min-width: unset;
    i {
      font-size: 14px;
      @include themed() {
        color: t($second-icon-combination);
      }
    }
  }
  .button-search {
    position: absolute;
    right: 87px;
    top: 1px;
    transform: rotate(270deg);
  }
  @include media-breakpoint-between(md, lg) {
    .button-more {
      position: absolute;
      right: 0;
      bottom: -5px;
    }
  }
  @include media-breakpoint-down(sm) {
    position: absolute;
    right: 0;
    top: 0;
    .button-more {
      top: 11px;
      bottom: unset;
    }
    .button-search {
      top: 12px;
      position: relative;
      right: 24px;
    }
  }
}
