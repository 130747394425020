@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .profile-panel-tooltip-wrap {
    position: relative;
    display: inline-block;
  }
  .profile-panel-tabs {
    padding: 17px 15px;
    .ms-Button {
      margin-right: 0;
      height: 28px;
      line-height: auto;
      &:hover {
        @include themed() {
          background-color: t($second-combination);
        }
      }
      @include themed() {
        color: t($second-text-combination);
      }
      &::before {
        height: 3px;
      }
    }
    .ms-FocusZone {
      border: none;
    }
    .ui-menu__item {
      border-bottom: 3px solid transparent;
      @include themed() {
        color: t($second-text-combination);
      }
      &.active {
        color: $purple;
        padding-bottom: 3px;
        font-weight: 600;
      }
      &:hover {
        padding-bottom: 5px;
        color: $purple;
      }
    }
    .ms-Button.is-selected {
      color: $purple;
      &::before {
        background-color: $purple;
      }
    }
  }

  .profile-panel-content {
    &.contact-tab {
      div {
        @include themed() {
          color: t($second-text-combination);
        }
        &.is-checked {
          .ms-Checkbox-checkbox {
            background-color: $purple;
          }
        }
        &.selected {
          color: $purple;
        }
        &.ms-Toggle-innerContainer {
          button[aria-checked='true'] {
            background-color: $purple;
          }
        }
      }
      h3 {
        padding: 0 10px;
        @include themed() {
          color: t($second-text-combination);
        }
      }
      .action-bar-wrap {
        padding: 10px 0;
      }
      .action-bar-buttons {
        .ui-button__content {
          color: $white;
        }
      }
      .ui-accordion {
        span {
          @include themed() {
            color: t($second-text-combination);
          }
        }
      }
      .ui-flex {
        padding: 10px;
      }
    }
    .ui-accordion__title {
      padding: 0;
      @include themed() {
        background-color: t($eleventh-combination);
        border-bottom: 1px solid t($sixth-border-combination);
      }
    }
  }

  .profile-panel-main {
    .profile-panel-accordion-multiple-wrap {
      .ui-accordion__title {
        @include themed() {
          background-color: transparent;
          border-bottom: 1px solid transparent;
        }
      }
    }

    .ui-accordion__title__content-wrapper {
      display: flex;
      outline: none;
      padding: 0;

      .ui-box {
        &:last-child {
          display: block;
          width: 100%;
          outline: none;
        }
      }
    }

    .accordion-heading {
      font-size: 14px;
      font-weight: 600;

      @include themed() {
        color: t($second-text-combination);
      }
    }

    .ui-accordion__title__content-wrapper[aria-expanded='true'] {
      .ui-icon {
        transform: rotate(180deg);
      }
    }

    .accordion-head-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px 15px 0;

      .ui-icon {
        transition: $dur $ease;
        @include themed() {
          color: t($nine-text-combination);
        }
      }

      &.active {
        background-color: $light-green;
      }
    }

    .accordion-head-box-multiple {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px 0 0;

      .accordion-heading {
        margin-left: 14px;
      }
    }

    .accordion-head-box-multiple-content {
      .ui-icon {
        svg {
          width: 12px;
        }
      }
    }

    .accordion-head-box-multiple-popup-triger,
    .accordion-head-box-multiple-popup-item {
      border: 0;
      box-shadow: none;
      background: transparent;
    }
    .ui-menu__item {
      padding: 6px 6px 6px 12px;
    }

    .accordion-head-box-multiple-popup-item {
      padding: 0 10px;

      i {
        color: $red;
      }

      .ui-button__content {
        color: $red;
        font-weight: 400;
        padding: 5px 0;
      }
      .ui-box {
        display: block;
      }
    }

    .accordion-head-box-multiple-popup {
      .ui-list__item {
        padding: 0;
      }

      .ui-popup__content__content {
        padding: 0;
      }

      .ui-list__itemheader {
        margin-right: 0;
      }
    }

    .ui-accordion__content {
      margin: 0;
      padding: 0;
      .profile-panel-activities-dropdowns {
        &.profile-panel-sendings {
          padding: 15px 20px 5px 15px;
          .ui-box {
            margin: 0 5px 0 0;
          }
        }
        .ui-box {
          margin: 5px;
        }
      }
      .profile-panel-sendings-dropdown {
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 5px;
        @include themed() {
          background-color: t($eleventh-combination);
        }
        .profile-panel-activities-filter {
          display: flex;
          align-items: end;
          .icon {
            color: $purple;
          }
          .profile-panel-dropdown-button {
            height: auto;
            text-align: left;
            min-width: auto;
            margin-right: 10px;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              width: 20px;
              height: 15px;
              left: -20px;
              top: 0;
            }
          }
        }
        .ui-input__input {
          @include themed() {
            background-color: t($third-combination);
            color: t($second-text-combination);
            &::placeholder {
              color: t($third-text-combination);
              opacity: 0.8;
            }
          }
        }
        .ui-input__icon {
          svg {
            @include themed() {
              fill: t($second-text-combination);
            }
          }
        }
        .profile-panel-activities-filter {
          [data-icon-name='ChevronDown'] {
            display: none;
          }
          .profile-panel-dropdown-button,
          i {
            color: $purple;
            padding: 0;
            span {
              font-weight: 600;
            }
          }
        }
      }
      .ui-table {
        margin-top: 8px;
        .ui-table__row {
          height: auto;
          min-height: 3rem;
          @include themed() {
            background-color: t($sixth-combination);
            color: t($second-text-combination);
          }
          &:not(:first-of-type):hover {
            border-color: transparent;
            @include themed() {
              background-color: t($third-combination);
            }
          }
          &:first-of-type {
            @include themed() {
              background-color: t($fourth-combination);
            }
          }
          .ui-table__cell {
            word-break: break-all;
          }
        }
      }
    }
  }
  .log-tab {
    padding: 0 16px 0 12px;
    .ms-DetailsHeader-cellName,
    .ms-DetailsRow-cell {
      font-size: 12px;
      font-weight: 400;
      @include themed() {
        color: t($second-text-combination);
      }
      &.purple {
        color: $purple;
      }
    }
    .ms-DetailsHeader {
      @include themed() {
        background-color: t($sixth-combination);
        color: t($second-text-combination);
      }
    }
    .ms-DetailsRow {
      @include themed() {
        background-color: t($sixth-combination);
      }
      &:hover {
        @include themed() {
          background-color: t($fourth-combination);
        }
      }
    }
    .ms-DetailsHeader-cell {
      &:hover {
        @include themed() {
          background-color: t($fourth-combination);
          color: t($second-text-combination);
        }
      }
    }
  }
  .profile-panel-media-wrap {
    .profile-panel-media-button {
      display: flex;
      justify-content: flex-end;
      padding: 20px 15px;

      .ui-box {
        margin: 2px;
      }

      svg {
        width: 8px;
        height: auto;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .profile-panel-tabs {
      .ui-menu__item {
        &:hover {
          color: $purple;
          padding: 0;
        }

        &.active {
          &::after {
            content: '';
            position: absolute;
            left: 2px;
            bottom: 0px;
            height: 3px;
            width: calc(100% - 4px);
            display: block;
            background-color: $purple;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .profile-panel-tabs {
      padding: 10px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      .ui-menu__item {
        &.active {
          &::after {
            content: '';
            position: absolute;
            left: 2px;
            bottom: 0px;
            height: 3px;
            width: calc(100% - 4px);
            display: block;
            background-color: $purple;
          }
        }
      }
    }
  }
  @media screen and (max-width: 320px) {
    .profile-panel-tabs {
      padding: 8px;

      .ui-menu__itemcontent {
        font-size: 10px;
      }
    }
    .profile-panel-main {
      .profile-panel-chart {
        left: 0;
      }
    }
  }
}

.profile-panel-tabs::-webkit-scrollbar {
  display: none;
}

.subscriptionButton {
  padding-left: 10;
  padding-bottom: 10;
}
.ui-popup__content {
  min-width: 113px;
  ul {
    width: 100%;
    min-width: 150px;
    &.ui-menubutton__menu {
      width: max-content;
    }
    @include themed() {
      background-color: t($seventh-combination);
      color: t($second-text-combination);
    }
    li {
      .menu-item__active {
        @include themed() {
          background-color: t($third-combination);
          font-weight: 600;
        }
      }
      &:hover {
        @include themed() {
          background-color: t($third-combination);
        }
      }
    }
  }
}
