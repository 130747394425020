@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.eidtable-fields-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.editable-fields-intro-dropdown {
  .ui-dropdown {
    max-width: 280px;
  }

  .ui-dropdown__container {
    max-width: 280px;

    @include themed() {
      color: t($second-text-combination);
      background-color: t($fourth-combination);
    }

    .ui-button {
      @include themed() {
        color: t($second-text-combination);
      }
    }

    .ui-list {
      @include themed() {
        background-color: t($sixth-combination);
      }

      .ui-dropdown__item {
        .ui-dropdown__item__checkable-indicator {
          .theme--dark & {
            filter: invert(84%) sepia(100%) saturate(11%) hue-rotate(140deg) brightness(106%) contrast(101%);
          }
        }
        @include themed() {
          background-color: t($sixth-combination);
        }
        &:hover {
          @include themed() {
            background-color: t($fourth-combination);
          }
        }
      }

      .ui-dropdown__item__header {
        @include themed() {
          color: t($second-text-combination);
        }
        svg {
          @include themed() {
            fill: t($second-icon-combination);
          }
        }
      }
    }

    .ui-icon {
      svg {
        @include themed() {
          fill: t($second-icon-combination);
        }
      }
    }
  }

  .ui-dropdown__items-list {
    max-width: 280px;

    @include themed() {
      background-color: t($sixth-combination);
    }

    .ui-dropdown__item__header {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
}

.editable-fields-intro-checkbox {
  .ui-text {
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;

    @include themed() {
      color: t($second-text-combination);
    }
  }
}
