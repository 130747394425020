@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.templates-wrapper {
  display: flex;
}

.templates {
  padding-bottom: 20px;
  .templates-cards {
    border: none;
    padding: 0 20px;
  }
}

.templates-title {
  font-size: 16px;
  font-weight: $semi-bold;
  color: $dark;
  @include themed() {
    color: t($second-text-combination);
  }
  line-height: 20px;
  margin: 0;
  margin-bottom: 15px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 12px;
  }
}

.templates-cards {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  @include media-breakpoint-down(sm) {
    justify-content: space-between;
  }
}

.templates-button-wrap {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $gray-light;
}

button.templates-button {
  font-size: 14px;
  color: $purple;
  margin-top: 20px;
  span {
    font-weight: $regular;
  }
}

.templates-top-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
  .templates-title {
    margin: 0 auto 0 0;
    font-weight: 600;
  }
}

.templates-filter {
  justify-content: flex-end;
  display: flex;
  .ui-input__icon {
    cursor: pointer;
  }
  .ui-button {
    &:hover {
      background-color: transparent;
      svg {
        fill: $purple;
      }
      .ui-button__content {
        color: $purple;
      }
    }
    @include themed() {
      color: t($second-text-combination);
    }
  }
}

.template-header {
  font-size: large;
}
.load-template-modul {
  .ms-Dialog-main {
    max-width: 95%;
    min-height: 90%;
  }
  .templates-top-area {
    justify-content: space-between;
    align-items: center;
  }
  .templates-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    padding-left: 24px;
    margin: 0;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .close-button {
    position: absolute;
    right: 0;
    top: 29px;
    border: none;
    min-width: auto;
    font-size: 16px;
    padding-right: 30px;
    background-color: transparent;
    @include themed() {
      color: t($second-text-combination);
    }
    &:hover {
      background-color: transparent;
      opacity: 0.6;
    }
  }
  .ms-Modal-scrollableContent {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 30px;
  }
  .templates-filter {
    justify-content: end;
    flex-direction: row-reverse;
    padding-right: 37px;
    .ui-box {
      width: auto;
    }
  }
  .table-filters-button-wrap {
    margin-left: auto;
    .ui-button {
      padding: 0 10px;
      margin: 0 5px;
      &:hover {
        @include themed() {
          background-color: t($second-combination);
        }
      }
    }
  }
  .table-filters-input-wrap {
    input {
      font-size: 14px;
      @include themed() {
        color: t($eight-text-combination);
        background-color: t($second-combination);
        border-color: transparent;
      }
    }
  }
  .templates-cards {
    padding: 20px 24px 0;
    align-items: flex-end;
    .template-card-wrap {
      max-width: 195px;
    }
  }
  .body-198 {
    overflow-y: auto;
  }
}
