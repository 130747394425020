@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.wizard-panel-preview-wrap {
  .wizard-panel-preview-intro {
    display: flex;
    padding: 3px;
    width: 100%;
  }
  .wizard-panel-preview-row {
    flex: 1 1 50%;
  }
  .wizard-panel-preview-col {
    display: flex;
  }
  .wizard-panel-preview-label {
    font-size: 12px;
    line-height: 24px;
    flex: 1 1 35%;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .wizard-panel-preview-content {
    display: flex;
    align-items: center;
    flex: 1 1 100%;

    i {
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .wizard-panel-preview-content-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
    flex: 1 1 100%;
    @include themed() {
      color: t($second-text-combination);
    }
    &.link {
      color: $purple;
      text-decoration: none;
      cursor: pointer;
    }

    &.error-text {
      color: $red;
    }

    &.tooltipText {
      flex: unset;
    }

    i {
      margin-right: 5px;
    }
  }
  .wizard-panel-preview-content-tooltip {
    display: block;
  }
}
.wizard-panel-preview-main {
  width: 100%;
  padding: 30px;
  text-align: center;
  background-color: $gray-lighten-1;
  text-align: center;
  margin-top: 17px;
  @include themed() {
    background-color: t($second-combination);
  }
  .wizard-panel-preview-image-wrap {
    max-width: 887px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    img {
      object-fit: cover;
      width: auto;
      height: auto;
    }
    .hidden {
      display: none;
    }
  }
}
