@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.empty-message {
  min-height: 160px;
  margin: 60px auto;
}

.empty-message-image {
  text-align: center;
  margin-bottom: 10px;
  img {
    transition: 0.3s ease;
  }
}

.empty-message-text {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  transition: 0.3s ease;
  .ui-text {
    font-size: 12px;
    line-height: 16px;
    display: block;
    @include themed() {
      color: t($third-text-combination);
    }
    &:first-child {
      font-weight: $bold;
    }
  }
}
