@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/themes/color-themes.scss';

.export-notification {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    .ui-attachment__header {
        color: $white;
    }
    .ui-attachment__action {
        svg {
            color: $white;
        }
    }
    .ui-attachment {
        background-color: $dark;
        transition: $dur-small $ease;
        &:hover {
            background-color: $dark-1;
        }
    }
    .ui-attachment__icon {
        svg {
            color: $white;
        }
    }
}