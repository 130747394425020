@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/themes/color-themes.scss';

.filter-panel {
  .ms-Panel-content {
    padding: 0;
    &:has(.wizard-panel-wrap.saved-search-table) {
      height: 850px;
    }
  }
  .ms-Panel-contentInner {
    overflow-x: hidden;
    @include themed() {
      background-color: t($seventh-combination);
    }
  }
  .profil-panel-about-input-wrap {
    padding: 15px;
    .ms-TextField-fieldGroup {
      @include themed() {
        color: t($second-text-combination);
        background-color: t($sixth-combination);
      }
      .ms-TextField-field {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  .ms-Panel-header {
    padding: 0 15px;
  }
  .ms-Panel-scrollableContent {
    margin-right: -6px;
    flex-grow: 1;
  }
  .date-wrap {
    padding: 15px;
    .accordion-head-box {
      .activities-date-datepicker {
        min-width: 120px;
        .ms-TextField-fieldGroup {
          @include themed() {
            background-color: t($sixth-combination);
          }
          .ms-TextField-field {
            span {
              @include themed() {
                color: t($second-text-combination);
              }
            }
          }
          i {
            @include themed() {
              color: t($second-text-combination);
            }
          }
        }
      }
    }
  }
  .button-creation-date-wrap {
    text-align: center;
  }
  .button-creation-date {
    width: 100%;
    margin: 5px auto;
  }
  .ms-Panel-closeButton {
    margin-right: 7px;
    &:hover {
      @include themed() {
        background-color: t($third-combination);
      }
    }
    i {
      @include themed() {
        color: t($second-icon-combination);
      }
    }
  }
  .ms-Panel-commands {
    padding: 10px 0;
    margin: 0;
    @include themed() {
      background-color: t($seventh-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
  }
  .ui-accordion__title {
    padding: 0;
    @include themed() {
      background-color: t($eleventh-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
  }
  .ms-Panel-headerText {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ui-accordion__title__content-wrapper {
    display: flex;
    outline: none;
    padding: 14px;
    .ui-box {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
        width: 100%;
        outline: none;
      }
    }
  }
  .ui-accordion__title__content-wrapper[aria-expanded='true'] {
    .ui-icon {
      transform: rotate(180deg);
    }
  }
  .accordion-head-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    label {
      margin: 0 5px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .ui-icon {
      color: $gray-dark;
      transition: $dur $ease;
    }
  }
  .accordion-heading {
    font-weight: $semi-bold;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ui-accordion__content {
    margin: 0;
    padding: 0;
  }
  .ui-radiogroup__item {
    @include themed() {
      color: t($second-text-combination);
    }
    .ui-icon {
      svg {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  .filter-panel-wrap {
    padding: 15px;
    .ui-checkbox__label {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .ui-checkbox {
      &:hover {
        opacity: 1;
      }
    }
    .ui-checkbox__indicator {
      .theme--dark & {
        border-color: rgba($white, 0.7);
        &:hover {
          border-color: rgba($white, 0.7);
        }
      }
    }
  }
  .filter-panel-dropdown-wrap {
    .filter-panel-dropdown {
      padding: 15px;
      @include themed() {
        background-color: t($eighth-combination);
      }
    }
    .ui-dropdown__container {
      width: 100%;
      background-color: $transparent;
      &:focus-within {
        border-bottom-color: transparent;
      }
    }
    .ui-dropdown__items-list {
      width: 100%;
      border-radius: 4px;
      @include themed() {
        background-color: t($seventh-combination);
      }
      .ui-dropdown__item {
        cursor: pointer;
        &.dp {
          .ui-dropdown__item__header {
            color: $dark;
          }
        }
        @include media-breakpoint-down(sm) {
          background: transparent;
        }
      }
      .ui-dropdown__item__header {
        @include themed() {
          color: t($second-text-combination);
        }
        @include media-breakpoint-up(md) {
          &:hover {
            color: $dark;
          }
        }
      }
    }
    .ui-dropdown__selected-items {
      border: 1px solid $gray;
      border-radius: 4px;
      .ui-input__input {
        @include themed() {
          color: t($third-text-combination);
        }
        &::placeholder {
          @include themed() {
            color: t($third-text-combination);
          }
        }
        @include media-breakpoint-down(sm) {
          font-size: 16px !important;
        }
      }
    }
    .ui-dropdown__toggle-indicator {
      svg {
        @include themed() {
          fill: t($second-text-combination);
        }
      }
    }
  }
  .filter-panel-pills {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    svg {
      width: 10px;
      height: 10px;
      @include themed() {
        fill: t($second-text-combination);
      }
    }
    .ui-button {
      min-width: 20px;
      height: auto;
      margin-left: auto;
      margin-top: 0;
      margin-bottom: -2px;
    }
    .filter-panel-pill {
      height: 25px;
      background: $white;
      border: 1px solid $gray-lighten-4;
      @include themed() {
        background-color: t($ninth-combination);
      }
    }
    .ui-pillcontent {
      padding: 2px 5px 4px 12px;
      font-size: 12px;
      line-height: initial;
      font-weight: 600;
      display: flex;
      align-items: center;
      width: 100%;
      @include themed() {
        color: t($fourth-text-combination);
      }
    }
  }
  .filter-panel-pill__name {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .filter-panel-checkbox {
    padding: 10px;
  }
  .filter-panel-checkbox-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .ui-checkbox[aria-checked='false'] {
      .ui-checkbox__label {
        @include themed() {
          color: t($third-text-combination);
        }
      }
    }
    .ui-checkbox[aria-checked='true'] {
      .ui-checkbox__label {
        @include themed() {
          color: t($third-icon-combination);
        }
      }
    }
  }
  .filter-panel-actions-wrap {
    height: 63px;
    width: 100%;
  }
  .filter-panel-actions-holder {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 340px;
    padding: 15px;
    @include themed() {
      background-color: t($seventh-combination);
      border-top: 1px solid t($sixth-border-combination);
    }
  }
  .filter-panel-actions-clear {
    color: $purple;
  }
  @include media-breakpoint-down(sm) {
    .filter-panel-actions-holder {
      width: 100%;
      justify-content: space-between;
    }
  }
}
