@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/abstracts/helpers';
@import 'sass/themes/color-themes';

.container.portal {
  .content {
    margin-top: 60px;
  }
  .wrapper.collapsed {
    .main-navigation {
      left: 74px;
      width: calc(100% - 74px);
    }
  }
  @include media-breakpoint-down(sm) {
    .content {
      margin-top: 90px;
    }
    .wrapper {
      .navigation {
        margin-top: 90px;
        margin-left: 0;
        padding-top: 0;
      }
    }
  }
}

.wrapper {
  width: 100%;
  &.collapsed {
    .navigation {
      width: 54px;
      .custom-item-text {
        display: none;
      }
    }
    .content {
      padding-left: 75px;
    }
    .navigation-menu-dropdown {
      padding: 0 10px 0 2px;
      .ui-box {
        margin-right: 0;
        margin-left: 0;
      }
      .ui-button {
        border-radius: 50%;
        min-width: 100%;
        max-width: 34px;
        padding: 0;
        min-width: 34px;
        width: 34px;
        height: 34px;
      }
    }
  }
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  @include themed() {
    background-color: t($fifth-combination);
  }
}

.content {
  position: relative;
  padding-left: 220px;
  width: 100%;
  transition: $dur-small cubic-bezier(0.37, 0.36, 0.63, 0.55);
  @include themed() {
    background-color: t($fifth-combination);
  }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    margin-top: 90px;
  }
}

.main-content {
  @include themed() {
    background-color: t($fifth-combination);
  }
}

.search-input-container {
  position: relative;
  z-index: 200;
}

.saved-searches {
  max-width: 1036px;
  margin: 0 auto;
  width: 100%;
  padding: 70px 20px 50px 20px;
  @include media-breakpoint-down(sm) {
    padding: 0 0 50px 0;
    overflow: hidden;
  }
}

div.ui-tooltip__content {
  font-size: 12px;
  line-height: 16px;
  z-index: 1000000;
  word-wrap: break-word;
  div:nth-of-type(2) {
    border-radius: 3px;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.container {
  &.animation--active {
    .banner-image {
      width: 85px;
    }
    .search-input {
      bottom: 40px;
    }
    .dropdown {
      top: -36px;
    }
    .search-wrap .search-help {
      top: -33px;
    }
  }
  @include media-breakpoint-down(lg) {
    &.animation--active {
      .search-input {
        bottom: 15px;
      }
      .dropdown {
        top: -14px;
      }
      .search-wrap .search-help {
        top: -10px;
      }
      .banner-image {
        width: 80px;
      }
    }
  }
}

.ms-Dialog-main {
  max-width: 600px;
  width: 100%;
  min-height: auto;
  @include media-breakpoint-down(sm) {
    margin: 0 17px;
    border-radius: 6px;
  }
  @include themed() {
    background-color: t($fifth-combination);
  }
  .wizard-panel-content {
    min-height: 72px;
  }
  .error-message {
    color: $red;
    font-size: 12px;
  }
  .ms-Dialog-button {
    z-index: 10;
  }
  .ms-Dialog-actions {
    margin-top: 39px;
    line-height: initial;
    div {
      button {
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
    }
  }
  .ms-Dialog-title {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 8px;
    @include themed() {
      color: t($second-text-combination);
    }
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  .ui-textarea,
  .ui-box {
    width: 100%;
    &.ui-input__icon {
      width: auto;
    }
  }
  .ui-input__input {
    @include themed() {
      color: t($second-text-combination);
      background-color: t($third-combination);
    }
  }
  .activities-log-form {
    .ui-input__input,
    .ui-textarea {
      @include themed() {
        color: t($second-text-combination);
        background-color: t($sixth-combination);
      }
    }
    .ms-TextField-fieldGroup {
      @include themed() {
        background-color: t($sixth-combination);
      }
      .ms-TextField-field {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  .ui-dropdown {
    .ui-dropdown__item__checkable-indicator,
    .ui-dropdown__toggle-indicator {
      width: 30px;
    }
  }
  .ui-button {
    box-shadow: none;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      min-width: 67px;
    }
  }
  .ms-Dialog-button {
    i {
      @include themed() {
        color: t($second-icon-combination);
      }
    }
    &:hover {
      background-color: $transparent;
    }
    @include media-breakpoint-down(sm) {
      position: relative;
      top: -10px;
    }
  }
  .ms-Dialog-content {
    #bee-plugin-container-preview {
      iframe {
        min-height: 900px;
      }
    }
    @include themed() {
      color: t($second-text-combination);
    }
    .ui-dropdown__container {
      @include themed() {
        color: t($second-text-combination);
        background-color: t($sixth-combination);
      }
      .ui-button__content {
        @include themed() {
          color: t($second-text-combination);
        }
      }
      svg {
        @include themed() {
          fill: t($second-text-combination);
        }
      }
      .ui-list {
        @include themed() {
          color: t($second-text-combination);
          background-color: t($sixth-combination);
        }
        .ui-dropdown__item {
          background-color: transparent;
          &:hover {
            @include themed() {
              background-color: t($fifth-combination);
            }
          }
        }
        .ui-dropdown__item__header {
          @include themed() {
            color: t($second-text-combination);
          }
        }
      }
    }
    p {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .tags-popup-trigger--icon {
      .ui-box {
        width: 16px;
      }
    }
    .tags-actions,
    .tags-intro-title {
      .ui-box {
        width: auto;
      }
    }
    .tags-actions {
      padding: 0;
      > .ui-button {
        min-height: 37px;
      }
    }
    .tag {
      min-width: auto;
      height: auto;
      @include themed() {
        border: 1px solid t($sixth-border-combination);
        color: t($fourth-text-combination);
      }
      .ui-pillcontent {
        padding: 1px 7px 1px 7px;
        width: 100%;
        .tag-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ui-button {
            min-width: auto;
            height: auto;
            margin-left: 2px;
          }
        }
      }
      .ui-button {
        margin-left: auto;
        svg {
          height: 10px;
          width: 10px;
          @include themed() {
            fill: t($second-icon-combination);
          }
        }
      }
      .tag-name {
        font-size: 12px;
        font-weight: 600;
      }
      &.tags-content-more {
        min-width: auto;
        background-color: $white;
        .ui-pillcontent {
          padding: 0 3px;
          min-width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        i {
          font-size: 7.5px;
          font-weight: 600;
        }
      }
    }
    .ui-menu__itemcontent {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .ui-button {
        margin-left: 5px;
        margin-top: 2px;
        svg {
          width: 14px;
        }
      }
    }
  }
  .ui-dropdown__container {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .ui-dropdown__items-list {
    width: 100%;
  }
}
.not-support {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #e5e5fa;
  padding: 70px 24px;
  position: relative;
}
.not-support__logo {
  text-align: center;
  position: relative;
  z-index: 2;
}
.not-support__text {
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #d5d6ea;
  height: auto;
  text-align: center;
  padding: 17px 30px 24px;
  margin-top: auto;
  position: relative;
  z-index: 1;
  h5 {
    margin: 0 0 11px;
    color: #7b61ff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  @include themed() {
    background-color: t($first-combination);
  }
}
.not-support__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 166px;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 655px;
    left: 50%;
    transform: translateX(-50%);
    &.not-support__ilustration {
      z-index: 1;
      top: 0;
      width: auto;
      height: 100%;
    }
  }
}
