@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-about-wrap {
    position: relative;
    padding: 15px 8px;
  }
  .profile-panel-about-intro {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .profile-panel-media-outlets-accordion,
  .profile-panel-company-accordion {
    padding: 15px 18px;
  }

  .profile-panel-about-dropdown {
    .ui-dropdown {
      max-width: 280px;
    }

    .ui-dropdown__container {
      max-width: 280px;

      @include themed() {
        background-color: t($sixth-combination);
      }

      .ui-button {
        @include themed() {
          color: t($second-text-combination);
        }
      }

      .ui-list {
        @include themed() {
          background-color: t($sixth-combination);
        }

        .ui-dropdown__item__header {
          @include themed() {
            color: t($second-text-combination);
          }
        }
      }

      .ui-icon {
        svg {
          @include themed() {
            fill: t($second-icon-combination);
          }
        }
      }
    }

    .ui-dropdown__items-list {
      max-width: 280px;

      @include themed() {
        background-color: t($sixth-combination);
      }

      .ui-dropdown__item__header {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }

  .profile-panel-about-checkbox {
    .ui-text {
      font-size: 12px;
      line-height: 14px;
      margin-top: 4px;

      @include themed() {
        color: t($second-text-combination);
      }
    }
  }

  .profile-panel-about-content {
    padding: 0 15px;
  }

  .profile-panel-about-column {
    display: flex;
    align-items: center;
    padding: 5px 0;
    max-height: 30px;
  }

  // .profile-panel-about-intro {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  // }

  .profile-panel-about-intro-text {
    font-weight: $semi-bold;
    margin: 0;

    @include themed() {
      color: t($second-text-combination);
    }
  }

  .profile-panel-about-intro-button {
    svg {
      color: $red;
    }
  }

  .profile-panel-about-column-title {
    font-weight: $semi-bold;
    line-height: 24px;
    color: $dark;
    font-size: 12px;
    flex: 1 1 160px;

    @include themed() {
      color: t($second-text-combination);
    }
  }

  .profile-panel-about-column-input-wrap,
  .profile-panel-about-column-content {
    position: relative;
    flex: 1 1 100%;
  }

  .profile-panel-about-column-input-wrap.input-dropdown {
    margin-left: 70px;
  }

  .profile-panel-about-column-input-wrap-wrap {
    max-width: 60%;
    .ui-dropdown__container {
      width: unset;
      .ui-input__input {
        font-size: 12px;
      }
    }
  }

  .profile-panel-about-column-multiple {
    position: relative;
    flex: 1 1 100%;
  }

  .profile-panel-about-column-multiple {
    position: relative;
    flex: 1 1 100%;
  }

  .profile-panel-about-column-multiple {
    position: relative;
    flex: 1 1 100%;
  }

  .profile-panel-about-column-input-name {
    position: relative;
    color: $dark;
    font-size: 12px;
    display: inline-block;
    max-width: 80%;
    padding: 0 10px;

    @include themed() {
      color: t($second-text-combination);
    }
  }

  .profile-panel-about-column-actions {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;

    .ui-icon {
      svg {
        width: 12px;
        height: 100%;

        @include themed() {
          fill: t($second-icon-combination);
        }
      }
    }
  }

  .profil-panel-about-input-wrap {
    position: relative;
    flex: 1 1 100%;

    .profil-panel-about-input {
      width: 100%;
    }

    .ui-input__input {
      background: transparent;
      border-bottom: 2px solid $purple;
      border-radius: 0;
      width: 80%;
      padding: 0 10px;
      font-size: 12px;
    }

    .ui-input__icon {
      right: -8px;
    }

    .profile-panel-input-actions {
      i[data-icon-name='Cancel'] {
        color: $red;
      }

      i[data-icon-name='CheckMark'] {
        color: $light-green;
        font-size: 17px;
      }
    }
  }

  .button-star {
    border: 0;
    background: $transparent;
    box-shadow: none;
    margin-top: -3px;

    svg {
      fill: $white;
      stroke: $dark;
      width: 11px;
      height: 100%;
    }

    &.button-star--primary {
      svg {
        fill: $yellow;
        stroke: $yellow;
      }
    }
  }

  .profile-panel-about-button {
    margin: 0 auto;

    .ui-button__content {
      color: $purple;
      font-weight: $regular;
    }
  }
  .profile-panel-edit-options-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    .ui-button {
      box-shadow: none;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
  .profile-panel-media-accordion {
    .profile-panel-about-column-input-wrap {
      padding: 5px 0;
    }
  }
  @include media-breakpoint-down(lg) {
    .profile-panel-about-wrap {
      .bottom-popup-icon-wrap {
        top: 3px;
        right: 0;
      }
    }
    .profile-panel-media-outlets-accordion,
    .profile-panel-company-accordion {
      position: relative;

      .bottom-popup-icon-wrap {
        right: 0;
        top: 0;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .profile-panel-about-content {
      padding: 0;
    }

    .profile-panel-activities-dropdowns {
      flex-wrap: wrap;
    }

    .profile-panel-activities-dropdown {
      max-width: 160px;
      width: 100%;
      margin-bottom: 10px;
      .profile-panel-popup-button {
        background-color: transparent;
        padding-left: 13px;
        @include themed() {
          color: t($second-text-combination);
        }
        .ui-button__content {
          font-weight: 300;
        }
      }
      .ui-dropdown {
        width: 100%;
      }

      .ms-Button {
        width: 100%;
      }
    }

    .profile-panel-activities-list {
      .ui-list__item__main {
        margin-left: 0;

        &:before {
          left: 2px;
        }

        &:after {
          left: 3px;
        }
      }

      .ui-list__itemheader {
        left: 20px;
      }

      .ui-list__itemheadermedia {
        .list-content {
          flex-direction: unset;
          align-items: unset;
        }
      }

      .ui-list__itemheadermedia {
        left: unset;
        right: 17px;
      }
    }
  }
}
