@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.quick-actions-wrap {
  display: flex;
  margin-top: 17px;
  .quick-action {
    width: 30px;
    height: 30px;
    .ui-avatar__icon {
      width: 30px;
      height: 30px;
    }
    .ui-icon {
      width: 16px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 9px;
    .ui-button {
      min-width: 27px;
    }
    .ui-avatar__icon {
      height: 27px;
      width: 27px;
      svg {
        width: 12px;
        margin: 0 auto;
      }
    }
  }
}

.quick-action {
  cursor: pointer;
  margin-right: 5px;
  @include media-breakpoint-down(sm) {
    width: 27px;
    height: 27px;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.quick-actions-wrap-buttons--teams {
  margin-right: 10px;
  button {
    opacity: 0.5;
    pointer-events: none;
  }
  @include media-breakpoint-down(sm) {
    margin-right: 6px;
  }
}

.quick-actions-wrap-buttons {
  margin-right: 10px;
}
