@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/animations';
@import 'sass/themes/color-themes';

.action-bar-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 8px 0;
  @include themed() {
    background-color: t($eleventh-combination);
  }
  &.action-bar-table {
    @include media-breakpoint-down(sm) {
      .action-bar-buttons {
        background-color: transparent;
        @include themed() {
          border-bottom: none;
        }
      }
    }
  }

  .action-bar-button {
    margin-left: 10px;
    .ui-button__content {
      font-size: 11px;
      line-height: 16px;
      display: block;
      transform: $dur-small $ease;
      @include themed() {
        color: t($third-text-combination);
      }
    }

    .ui-button {
      min-width: 66px;
      padding: 0 5px;
    }

    .ui-box {
      margin-right: 5px;
      &.ui-input__icon {
        cursor: pointer;
      }

      i {
        font-size: 14px;
        line-height: 16px;
        transform: $dur-small $ease;
        @include themed() {
          color: t($third-text-combination);
        }
      }
    }
    .ui-input__input {
      @include themed() {
        color: t($second-text-combination);
        background-color: t($third-combination);
      }
      &::placeholder {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
    .tags-checkbox {
      .ui-button {
        min-width: auto;
        i {
          font-size: 16px;
          color: $red;
        }
      }
    }
    .tags-actions {
      i {
        font-size: 16px;
      }
      .tags-button-delete {
        i {
          color: $red;
        }
      }
    }
    .tags-intro-title {
      i {
        font-size: 16px;
      }
    }
    & > .ui-box {
      color: $dark-light;
    }
  }

  .action-bar-selected {
    font-size: 14px;
    font-weight: $semi-bold;
    line-height: 20px;

    @include themed() {
      color: t($second-text-combination);
    }
  }

  .action-bar-buttons {
    display: flex;
    align-items: center;
    .ui-button__content {
      @include themed() {
        color: t($eight-text-combination);
      }
    }
  }
  &.saved-search-wrap {
    .action-bar-buttons {
      width: 100%;
      .ui-button__content {
        color: $white;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .action-bar-button :hover > {
      .ui-box {
        i {
          color: $purple;
        }
      }

      .ui-button__content {
        color: $purple;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;

    .action-bar-buttons {
      justify-content: space-between;
      padding: 8px 0;
      background-color: rgba(139, 140, 199, 0.2);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      @include themed() {
        border-bottom: 3px solid t($fourth-combination);
      }
    }

    .action-bar-selected {
      padding: 10px 15px;
      width: 100%;
      display: block;
      margin-top: -40px;
      @include themed() {
        background-color: t($fourth-combination);
      }
    }

    .action-bar-button {
      .ui-button {
        min-width: 70px;
        display: flex;
        flex-direction: column;
        height: auto;
      }

      .ui-box {
        margin-right: 0;
      }
    }
  }
}
