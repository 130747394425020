@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.wizard-empty-info {
  margin-top: 40px;
  .wizard-empty-info-image {
    text-align: center;
  }
  .wizard-empty-info-text {
    display: block;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    @include themed() {
      color: t($third-text-combination);
    }
    &.text-bold {
      margin-top: 16px;
      font-weight: $bold;
    }
  }
}
