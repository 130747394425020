@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.table-filters-wrap {
  display: flex;
  justify-content: space-between;
  .table-filters-input-wrap {
    .ui-input__icon {
      cursor: pointer;
      svg {
        @include themed() {
          fill: t($second-text-combination);
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
    .table-filters-item:last-child {
      align-self: flex-end;
    }
  }

  &.hidden {
    visibility: hidden;
  }
}

.table-filters-quick-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

span.table-filter-pill {
  height: auto;
  background: $white;
  border: 1px solid $gray-lighten-4;
  cursor: pointer;
  padding: 3px 10px 5.5px;
  min-width: auto;
  display: flex;
  align-items: center;
  &:hover {
    .ui-pill__icon {
      color: $dark;
    }
  }
  &.active {
    background-color: $purple;
    .ui-pill__icon,
    .ui-pillcontent {
      color: $white;
    }
  }
  .ui-pill__icon {
    margin-left: 0;
    color: $dark;
    width: 16px;
    height: 16px;
    display: inline-block;
    i {
      height: 100%;
      position: relative;
      top: -2px;
    }
  }
  &--big {
    .ui-pill__icon {
      i {
        top: -3.2px;
      }
    }
  }
  .ui-pillcontent {
    min-height: 0;
    padding: 0;
    font-size: 13px;
    line-height: initial;
    font-weight: 600;
    color: $purple;
    margin-left: 3px;
  }
}

.table-filters-input {
  &.expanded {
    .ui-input__input {
      max-width: 230px;
    }
  }
  .ui-icon {
    svg {
      cursor: pointer;
    }
  }
  .ui-input__input {
    max-width: 170px;
    width: 100%;
    border: 1px solid $silver-dark;
    background-color: $white;
    border-radius: 3px;
    transition: $dur $ease;
    @include media-breakpoint-down(sm) {
      max-width: 140px;
    }
    @include themed() {
      background-color: t($sixth-combination);
      color: t($second-text-combination);
    }
  }
  ::placeholder {
    opacity: 0.8;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  i {
    font-size: 14px;
    transform: rotate(270deg);
    @include themed() {
      color: t($second-text-combination);
    }
  }
}

.table-filters-item {
  display: flex;
}

button.table-filters-button {
  box-shadow: none;
  border: 0;
  background-color: transparent;
  @include themed() {
    color: t($second-text-combination);
  }
  &:hover {
    background-color: transparent;
    color: $purple;
  }
  .ui-box {
    margin: 0;
  }
  .ui-button__content {
    margin-left: 5px;
  }
  &:focus {
    border: transparent;
  }
}
