@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/themes/color-themes';

.profile-image-dialog-wrap {
  .ms-Dialog-title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 24px;
    padding: 20px 30px;
  }
  .ms-Dialog-inner {
    padding: 0 30px 20px 30px;
    .ui-button {
      box-shadow: none;
    }
  }
  .ms-Dialog-main {
    box-shadow: 0px 1.2px 3.6px rgba($black, 0.11), 0px 6.4px 14.4px rgba($black, 0.13);
    border-radius: 4px;
    max-width: 388px;
    width: 100%;
  }
  .profile-image-dialog-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile-image-dialog-upload-button,
  .profile-image-dialog-remove-button {
    border: 0;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .ui-icon,
    i {
      margin-right: 10px;
      margin-top: 2px;
      @include themed() {
        color: t($fifth-text-combination);
      }
      svg {
        transition: $dur-small $ease;
        width: 12px;
        @include themed() {
          color: t($fifth-text-combination);
        }
      }
    }
  }
  .profile-image-dialog-upload {
    margin-bottom: 15px;
  }
  .profile-image-dialog-photo-avatar {
    width: 100px;
    height: 100px;
    .ui-label {
      width: 100%;
      height: 100%;
      padding: 36px 12px 24px;
      font-size: 26px;
      font-weight: 700;
      background-color: #d1ebde;
      color: #1c5d3b;
    }
  }
  .profile-image-dialog-actions {
    margin-right: 30px;
  }
  .profile-image-dialog-upload-name,
  .profile-image-dialog-remove-name {
    font-size: 14px;
    line-height: 24px;
    color: $dark;
    transition: $dur-small $ease;
    @include themed() {
      color: t($fifth-text-combination);
    }
  }
  @include media-breakpoint-up(xl) {
    .profile-image-dialog-upload-button,
    .profile-image-dialog-remove-button {
      &:hover {
        .profile-image-dialog-upload-name,
        .profile-image-dialog-remove-name {
          color: $purple;
        }
        .ui-icon {
          svg {
            color: $purple;
          }
        }
      }
    }
  }
}
