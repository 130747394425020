@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-comments-button-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 35px;
    width: 100%;
    @include themed() {
      background-color: t($fourth-combination);
    }
  }
  .profile-panel-comments-send-input {
    width: 100%;
    input {
      width: 100%;
      border-radius: 3px;
      padding: 8px;
      box-shadow: 0px 0.15px 0.45px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
      @include themed() {
        background-color: t($eighth-combination);
        color: t($second-text-combination);
      }

      &::placeholder {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
    .ui-input__icon {
      svg {
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }

    button {
      &[disabled] {
        opacity: 0.7;
      }
    }
  }
  .profile-panel-comments-wrap {
    padding: 35px;
  }
  .profile-panel-comments-list {
    .ui-list__item {
      position: relative;
      padding: 10px;
      margin-left: 45px;
      margin-bottom: 20px;
      box-shadow: 0px 0.15px 0.45px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
      @include themed() {
        background-color: t($sixth-combination);
      }
    }
    .ui-avatar {
      position: absolute;
      left: -45px;
      top: 0;
      .ui-label {
        background-color: #d1ebde;
        color: #1c5d3b;
      }
    }
    .ui-list__itemheader {
      font-size: 12px;
      line-height: 16px;
      font-weight: $bold;
      @include themed() {
        color: t($eight-text-combination);
      }

      .details {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
    .ui-list__itemheadermedia {
      color: $dark-light;
      @include themed() {
        color: t($eight-text-combination);
      }
    }
    .ui-list__itemcontent {
      color: $dark-light;
      @include themed() {
        color: t($eight-text-combination);
      }
    }
    .ui-button {
      .ui-button__content {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
}
.person-avatar {
  @include themed() {
    --person-line1-text-color: t($second-text-combination);
  }
}
