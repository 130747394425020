@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';

.alert-dialog {
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  min-width: 300px;
  border-radius: 3px;
  z-index: 2000000;
  transition: $dur-small cubic-bezier(0.37, 0.36, 0.63, 0.55);

  .ui-alert {
    padding: 7px 10px 7px 18px;
    background-color: $dark-gray-light-1;
    border-radius: 3px;
    border: 0;
  }
  .ui-alert__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .alert-dialog-content {
    font-size: 12px;
    line-height: 16px;
    color: $white;
    text-transform: capitalize;
    word-break: break-all;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  .alert-dialog-button {
    background-color: transparent;
    border: 1px solid $white;
    box-shadow: none;
    outline: 0;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    padding: 0 10px;
    min-width: 60px;
    margin: 0 10px;
    display: inline-block;
  }
  @include media-breakpoint-down(sm) {
    min-width: 290px;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: unset;
    .ui-alert {
      padding: 15px 10px 15px 15px;
    }
  }
}
