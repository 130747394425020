@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.table-paging {
  display: flex;
  margin: 1.2rem 0;
  padding-left: 20px;
  align-items: center;
  button {
    &[disabled] {
      opacity: 0.5;
    }
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ui-button__content {
    .selected {
      color: $purple;
      font-weight: 700;
    }
  }
  .ui-dropdown__container {
    @include themed() {
      background-color: t($fourth-combination);
    }
  }
  .ui-list {
    @include themed() {
      background-color: t($sixth-combination);
    }
    .ui-dropdown__item {
      &:hover {
        @include themed() {
          background-color: t($fourth-combination);
        }
      }
    }
    .ui-dropdown__item__header {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .paging-dropdown {
    width: 60px;
    margin-left: 1rem;
  }
}
