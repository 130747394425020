@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-gdpr {
    padding: 15px 18px;
  }
  .profile-panel-gdpr-accordion {
    .ui-accordion__title {
      @include themed() {
        background-color: t($eleventh-combination);
        border-bottom: 1px solid t($sixth-border-combination);
      }
    }
  }
  .profile-panel-gdpr-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .profile-panel-gdpr-button {
      background-color: $purple-dark;
      &:hover {
        opacity: 0.8;
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .profile-panel-gdpr-text {
    font-weight: $semi-bold;
    margin: 0;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .profile-panel-gdpr-item {
    .ui-checkbox__indicator {
      @include themed() {
        border-color: t($second-text-combination);
      }
      &::before {
        @include themed() {
          border-color: t($second-text-combination);
        }
      }
    }
  }
  .profile-panel-gdpr-red-status {
    position: relative;
    width: 17px;
    height: 17px;
    i {
      color: $red;
      font-size: 17px;
    }
  }
  .profile-panel-gdpr-green-status {
    position: relative;
    width: 17px;
    height: 17px;
    i {
      color: $light-green;
      font-size: 17px;
    }
  }
}
