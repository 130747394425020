@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.main-navigation {
  position: absolute;
  left: 220px;
  top: 0;
  width: calc(100% - 220px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @include themed() {
    background-color: t($fifth-combination);
    box-shadow: 0px 1px 3px rgba(t($first-box-shadow-combination), 0.25);
  }
  @include box-shadow(0.05);
  padding: 7px 20px;
  transition: $dur-small cubic-bezier(0.37, 0.36, 0.63, 0.55);
  z-index: 2000;
  @include media-breakpoint-down(sm) {
    left: 0;
    width: 100%;
    padding: 5px 15px;
    border-bottom: 1px solid rgba($dark, 0.3);
  }
}

.main-navigation-actions {
  display: flex;
  justify-content: space-between;
}

.main-navigation-localization-button {
  margin-right: 15px;
  .ui-dropdown__container,
  ul {
    width: 100px;
  }
}

.main-navigation-button {
  box-shadow: 0 4px 4px rgba($black, 0.05);
  @include themed() {
    background-color: t($tenth-combination);
    border: 1px solid t($fifth-border-combination);
  }
  &:last-child {
    margin-left: 15px;
  }
  .ui-button__content {
    font-size: 14px;
    line-height: 19px;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  @include media-breakpoint-up(xl) {
    &:hover {
      @include themed() {
        background-color: t($tenth-combination);
        border: 1px solid t($fifth-border-combination);
      }
    }
  }
}

ul.main-navigation-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
  min-height: unset;
  height: unset;
  .ui-menu__itemwrapper {
    border: 0;
    box-shadow: none;
    background: transparent;
    min-height: unset;
    height: unset;
  }
  span.ui-menu__itemicon {
    vertical-align: unset;
    margin-bottom: 0;
    margin-right: 0;
  }
}

a.main-navigation-item {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  font-weight: $regular;
  line-height: 20px;
  @include themed() {
    color: t($fifth-text-combination);
  }
  &[tabindex='0'] {
    font-weight: 600;
    color: $purple;
    position: relative;
    border-radius: 0 0 2px 2px;
  }
  transition: $dur-small $ease;
  &:not(:last-child) {
    margin-right: 15px;
    border-right: 1px solid rgba($dark, 0.3);
    padding-right: 15px;
  }
  &.active {
    color: $purple;
  }
  i {
    margin-right: 12px;
  }
  @include media-breakpoint-up(xl) {
    &:hover {
      color: $purple;
    }
  }
}
