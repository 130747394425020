@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.editable-fields-wrap {
  position: relative;
  padding: 10px 8px;
}

.editable-fields-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editable-fields-content {
  padding: 0 15px;
}

.editable-fields-row {
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 2px;
}

.editable-fields-row-label {
  flex: 1 1 160px;
  font-weight: 400;
  line-height: 24px;
  color: $dark;
  font-size: 12px;
  @include themed() {
    color: t($second-text-combination);
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}
.editable-fields-error-msg {
  color: $red;
  font-size: 12px;
  line-height: 18px;
  display: none;
  padding-top: 2px;
}

.editable-fields-row-content {
  position: relative;
  flex: 1 1 100%;
}

.editable-fields-input {
  position: relative;
  flex: 1 1 100%;
  &.error {
    .editable-fields-error-msg {
      display: block;
    }
  }
  .input-wrap {
    width: 100%;
    &.error {
      .ui-input__input {
        border-bottom: 2px solid $red;
      }
    }
  }

  .ui-input__input {
    background: transparent;
    border-bottom: 2px solid $purple;
    border-radius: 0;
    width: 80%;
    padding: 0 12px 4px;
    font-size: 12px;
    @include themed() {
      color: t($second-text-combination);
    }
  }

  .ui-input__icon {
    right: -8px;
  }
}

.editable-fields-value {
  position: relative;
  display: inline-block;
  max-width: 80%;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  word-break: break-all;

  @include themed() {
    color: t($second-text-combination);
  }
  &.editable-fields-empty {
    opacity: 0.6;
  }
  &.link {
    text-decoration: none;
    color: $purple;
    transition: $dur-small $ease-out;
    &:hover {
      color: $purple-light;
    }
  }
}

.editable-fields-input-actions {
  i[data-icon-name='Cancel'] {
    color: $red;
  }

  i[data-icon-name='CheckMark'] {
    color: $light-green;
    font-size: 17px;
  }
}

.editable-fields-actions {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;

  .ui-icon {
    svg {
      width: 12px;
      height: 100%;

      @include themed() {
        fill: t($second-icon-combination);
      }
    }
  }

  button {
    &[disabled] {
      opacity: 0.7;
    }
  }
}
.ms-Callout-main {
  @include themed() {
    background-color: t($first-combination);
    color: t($second-text-combination);
  }
  td,
  th,
  div,
  i,
  .js-goToday {
    @include themed() {
      color: t($second-text-combination);
    }

    &.ms-CalendarDay-daySelected {
      @include themed() {
        background-color: t($fourth-combination) !important;
      }
    }
  }
  td,
  button {
    &:hover {
      @include themed() {
        background-color: t($fourth-combination);
      }
    }
  }
}
.editable-fields-row-content-dropdown {
  position: relative;
  max-width: 75%;
  margin: 0 0 0 20px;
  padding-top: 2px;
  @include media-breakpoint-down(sm) {
    max-width: 61%;
  }
  .ms-TextField-fieldGroup {
    @include themed() {
      background-color: t($first-combination);
    }
    i {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .ms-TextField-field {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ui-dropdown__container {
    width: unset;
    color: $dark;
    @include themed() {
      background-color: t($fourth-combination);
    }
    svg {
      @include themed() {
        fill: t($second-text-combination);
      }
    }
    .ui-input__input {
      font-size: 12px;
      line-height: 16px;
      padding: 5px 10px;
      &::placeholder {
        opacity: 0.8;
        @include themed() {
          color: t($second-text-combination);
        }
      }
      @include themed() {
        color: t($second-text-combination);
      }
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  }

  .editable-fields-input-actions {
    position: absolute;
    right: -90px;
    top: 1px;
    @include media-breakpoint-down(sm) {
      right: -70px;
    }
  }

  .ui-list {
    width: 100%;
    max-height: 180px;
    @include themed() {
      background-color: t($first-combination);
    }
    .ui-dropdown__item {
      &.dp {
        @include themed() {
          background-color: t($fourth-combination);
        }
      }
      &:hover {
        @include themed() {
          background-color: t($fourth-combination);
        }
      }
    }
    .ui-dropdown__item__header,
    .ui-dropdown__item__content {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
}

.editable-fields-button {
  text-align: center;

  .ui-button__content {
    color: $purple;
    font-weight: $regular;
  }
}

.editable-fields-row-content--multiple-selected {
  .ui-dropdown__selecteditem {
    :first-child svg {
      height: 12px;
      margin-top: 3px;
      fill: $dark;
    }
  }
  .ui-dropdown__selecteditem__header {
    font-size: 12px;
  }
}
