@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/abstracts/helpers';
@import 'sass/themes/color-themes';
@import 'sass/abstracts/variables';
.login-wrap {
  min-height: 100vh;
  display: flex;
  @include themed() {
    background-color: t($seventh-combination);
  }
  @include media-breakpoint-down(sm) {
    overflow: hidden;
    height: 100%;
    min-height: 100vh;
  }
}

.login-actions-col {
  text-align: center;
  background-color: $purple;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 438px;
  width: 100%;
  padding: 68px 20px 32px 19px;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    padding: 75px 10px 31px;
    background-color: $white;
    overflow: hidden;
    .theme--dark & {
      background-color: $dark-2;
    }
  }
  .license-not-found & {
    @include media-breakpoint-down(sm) {
      background-color: #e5e5fa;
    }
  }
}
.login-content {
  position: relative;
  height: 100%;
}

.login-logo {
  margin-bottom: 293px;
  img {
    max-width: 205px;
    width: auto;
    &.login-logo-mobile {
      display: none;
    }
  }
  &__no-user {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      position: static;
      transform: none;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 41px;
    img {
      display: none;
      margin: 0 auto;
      max-width: 178px;
      &.login-logo-mobile {
        display: block;
        .theme--dark & {
          display: none;
        }
      }
      .theme--dark & {
        display: block;
      }
    }
  }
}

.login-avatar-col {
  position: relative;
  z-index: 0;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.login-text {
  font-size: 40px;
  font-weight: $light;
  line-height: 50px;
  color: $white;
  margin: 0;
  margin-bottom: 5px;
  @include media-breakpoint-down(sm) {
    color: #252423;
    font-size: 32px;
    margin-bottom: 5px;
    .theme--dark & {
      color: $white;
    }
  }
}

.login-description {
  font-size: 18px;
  line-height: 22px;
  color: $white;
  display: block;
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    color: #6e6e6e;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 auto 8px;
    max-width: 290px;
    .theme--dark & {
      color: $white;
    }
  }
}

button.login-button {
  position: relative;
  background: $white;
  border-radius: 2px;
  min-width: 254px;
  min-height: 56px;
  z-index: 1000;
  &:hover {
    opacity: 0.7;
    background: $white;
  }
  .ui-button__content {
    font-size: 22px;
    font-weight: $semi-bold;
    line-height: 34px;
    @include themed() {
      color: t($tenth-text-combination);
    }
  }
  @include media-breakpoint-down(sm) {
    background-color: $purple;
    border-color: $purple;
    margin-bottom: 45px;
    &:hover {
      background: $purple;
    }
    .ui-button__content {
      color: $white !important;
    }
  }
}

.login-footer-text {
  font-size: 18px;
  line-height: 34px;
  color: $white;
}

.login-image-decoration {
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  .theme--dark & {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.login-bg-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 783px;
  padding: 0 20px;

  .theme--dark & {
    display: none;
    max-width: 783px;
    @include media-breakpoint-down(sm) {
      &--mobile-dark {
        display: block;
      }
    }
  }
  &--mobile {
    display: none;
  }
  &--dark {
    display: none;
    .theme--dark & {
      display: block;
    }
  }
  @include media-breakpoint-down(sm) {
    position: static;
    transform: none;
    margin: 20px auto;
    padding: 0;
    &--mobile {
      display: block;
      &-dark {
        display: none;
      }
    }
  }
}

.login-not-found__user {
  background-color: $dark-white;
  max-width: 831px;
  width: 100%;
  margin: 0 20px;
  padding: 52px 20px;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(204, 204, 231, 0.9);
    z-index: -1;
  }
  &--mobile {
    display: none;
    border-radius: 2px;
    @include media-breakpoint-down(sm) {
      display: block;
      padding: 22px 20px;
      max-width: 100%;
      margin: 0;
      &::before {
        display: none;
      }
    }
  }
}
