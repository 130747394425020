@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';
.preview-tab {
  padding: 20px 0;
}
.preview-tab-details-row {
  display: flex;
  align-items: center;
  padding: 6px 30px;
  font-size: 12px;
  @include themed() {
    color: t($second-text-combination);
  }
  &-item {
    flex: 1 1 50%;
    @include text-truncate;
  }
  a {
    color: $purple;
  }
  .link {
    color: $purple;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.email-report-tab {
  @include themed() {
    color: t($second-text-combination);
  }
}
.email-report-text {
  padding: 15px 20px;
}
