@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.dialog-wrap {
  .ms-Dialog-main {
    max-width: 600px;
    width: 100%;
    min-height: 160px;
  }
  .ms-Dialog-title {
    padding: 20px 20px 10px 20px;
  }
}

.dialog-wrap--update {
  &.contact-update {
    .tags-popup .tags-popup-trigger {
      padding: 3px;
      min-width: 1px;
      .ui-box {
        width: 16px;
        margin-right: 0;
      }
    }
    .ms-Modal-scrollableContent > div {
      overflow-y: visible;
    }
    .ms-Modal-scrollableContent {
      overflow: visible;
    }
    .ms-Dialog-main {
      overflow: visible;
    }
    .ui-popup__content {
      padding-bottom: 10px;
      top: 5px !important;
    }
    .ui-checkbox__indicator {
      min-width: 16px;
      height: 16px;
    }
  }
  .ms-Dialog-inner {
    padding-bottom: 24px;
  }
}

.dialog-purple-btn {
  background-color: $purple;
  border-color: $purple;
  &:hover {
    background-color: $purple-dark;
  }
  &.is-disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
.dialog-outline-btn {
  border-color: $gray-lighten-4;
}
