@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.wizard-panel-collaboration-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 7px;
    background-color: $gray-lighten-1;
    margin-bottom: 8px;
}

.wizard-panel-collaboration-toolbar-button {
    .ui-button {
        padding: 0;
        border: 0;
    }
    .ui-button__content {
        font-size: 12px;
        font-weight: $regular;
    }
    .ui-box {
        margin-right: 3px;
    }
    i {
        font-size: 10px;
        font-weight: $regular;
    }
}

.wizard-panel-collaboration-toolbar-dropdown {
    max-width: 300px;
    width: 100%;
    .ui-dropdown__container {
        width: 100%;
        @include themed() {
            background-color: t($fifth-combination);
        }
    }
    .ui-input__input {
        font-size: 12px;
        font-weight: $regular;
    }
    .ui-list {
        max-width: 300px;
    }
}

.wizard-panel-collaboration-toolbar-search {
    .ui-dropdown__container {
        width: 100%;
        @include themed() {
            background-color: t($fifth-combination);
        }
    }
    .ui-input__input {
        font-size: 12px;
        font-weight: $regular;
    }
}

.wizard-panel-collaboration-table {
    .ui-table__header {
        height: 32px;
        background-color: $gray-lighten-1;
    }
    .ui-table__cell__content {
        max-width: 100px;
        @include text-truncate;
    }
    .wizard-panel-collaboration-table-button {
        i {
            color: $red;
        }
    }
}