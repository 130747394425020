@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.editable-fields-accordion-wrap {
  .ui-accordion__title {
    @include themed() {
      background-color: transparent;
      border-bottom: 1px solid transparent;
    }
  }
}

.editable-fields-accordion-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  .editable-fields-accordion-heading {
    margin-left: 9px;
  }
}

.editable-fields-accordion-content {
  .ui-icon {
    svg {
      width: 12px;
      path {
        @include themed() {
          fill: t($second-text-combination);
        }
      }
    }
  }
}

.editable-fields-accordion-heading {
  font-size: 12px;
  font-weight: 600;

  @include themed() {
    color: t($second-text-combination);
  }
}

.editable-fields-accordion-action {
  .ui-list__item {
    padding: 0;
  }

  .ui-popup__content__content {
    padding: 0;
  }
  .ui-list__itemheader {
    margin-right: 0;
  }

  .ui-button {
    min-width: 40px;
  }

  .ui-popup__content {
    ul {
      .ui-button {
        width: 100%;
      }
    }
  }
}
