@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.custom-accordion {
  margin-bottom: 15px;
  .custom-accordion-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    button {
      @include themed() {
        color: t($sixth-text-combination);
      }
    }
  }
  .custom-accordion-head-icon {
    margin-right: 10px;
    i {
      font-size: 10px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .expanded {
      transform: rotate(180deg);
    }
  }
  .custom-accordion-head-icon-title {
    font-size: 12px;
    font-weight: $bold;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .custom-accordion-head-actions-item-text {
    color: $red;
    font-size: 14px;
  }
  .custom-accordion-head-actions-item {
    i {
      font-size: 12px;
      color: $red;
      margin-right: 5px;
    }
  }
}
