@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.number-wrap {
    display: flex;
    margin-bottom: 15px;
    &:nth-child(odd) {
        .number-box {
            background-color: $white;
            border-color: $gray-lighten-1;
        }
    }
    &:nth-child(even) {
        .number-box {
            background-color: $gray-lighten-1;
            border-color: $gray-lighten-1;
        }
    }
    .table-results-wrap {
        min-width: 1px;
        width: 100%;
    }
}

.number-box {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 15px;
    border: 1px solid;
    min-width: 1px;
}

.number-box-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 18px;
    font-weight: $semi-bold;
}