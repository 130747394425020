@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.emailngs-action-bar {
  display: flex;
  min-height: 40px;
  .ui-button {
    span {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .tags-intro {
    padding-bottom: 10px;
  }
  .tags-intro-title {
    margin: 15px 0 5px;
    .ui-button {
      justify-content: flex-start;
      padding: 0;
    }
  }
  .tags-actions {
    text-align: center;
    padding-top: 10px;
    @include themed() {
      border-top: 1px solid t($second-text-combination);
    }
  }
  .ui-popup__content__content {
    padding: 0 15px 5px;
    @include themed() {
      background-color: t($first-combination);
      color: t($second-text-combination);
    }
  }
  .ui-checkbox__label {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .tags-content-list {
    max-height: 120px;
    overflow: auto;
  }
}

button.emailngs-action-bar-button {
  font-size: 16px;
  transition: 0.2s ease;
  @include themed() {
    color: t($second-text-combination);
  }
  &:hover {
    opacity: 0.7;
  }
  &.danger {
    color: $red;
    .ui-button__content {
      color: $red;
    }
  }
}
