@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.template-new-card {
  margin-bottom: 12px;
  margin-right: 15px;
}

.template-new-card-content {
  position: relative;
  min-width: 192px;
  min-height: 134px;
  padding: 10px;
  box-shadow: 0px 0.15px 0.45px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
  border-radius: 4px;
  cursor: pointer;
  @include themed() {
    background-color: t($first-combination);
  }

  @include media-breakpoint-down(lg) {
    min-width: 140px;
    margin-bottom: 12px;
  }
  @include media-breakpoint-down(md) {
    min-width: 150px;
  }
}

.template-new-card-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 12px;
  border-radius: 50%;
  border: 1px solid $purple;

  i {
    font-size: 20px;
    color: $purple;
  }
}

.template-new-card-text {
  font-size: 14px;
  font-weight: $semi-bold;
  line-height: 20px;
  display: flex;
  height: 32px;
  align-items: center;
  @include themed() {
    color: t($second-text-combination);
  }
  @include media-breakpoint-down(lg) {
    font-size: 12px;
    line-height: 16px;
  }
}
