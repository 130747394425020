@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.online-content-dialog-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  align-items: center;
  @include themed() {
    background-color: t($eleventh-combination);
  }
  .ui-popup__content__content {
    @include themed() {
      border: 1px solid t($thirteen-combination);
    }
    ul {
      padding: 8px 0;
      border: none;
      li {
        padding: 6px 0;
        .ui-menu__item {
          display: flex;
          justify-content: space-between;
          line-height: initial;
          &.menu-item__active {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.online-content-dialog-toolbar-search {
  display: flex;
  align-items: center;

  .online-content-dialog-toolbar-items {
    font-size: 12px;
    line-height: 20px;
    margin-right: 10px;
    padding-left: 15px;
    @include themed() {
      color: t($seven-text-combination);
    }
  }

  .ui-input__input {
    font-size: 12px;
    line-height: 20px;
    &::placeholder {
      @include themed() {
        color: t($seven-text-combination);
        opacity: 0.8;
      }
    }
    @include themed() {
      background-color: t($fifth-combination);
      color: t($seven-text-combination);
    }
  }

  .ui-input__icon {
    svg {
      width: 14px;
      @include themed() {
        fill: t($seven-text-combination);
      }
    }
  }
}

.online-content-dialog {
  div.ms-Dialog-title {
    margin: 20px 0px 30px;
  }
}

.table-checkbox {
  div.ui-checkbox__indicator {
    width: 1rem;
  }
}
