@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.flatfile_iframe-wrapper {
  z-index: 9999 !important;
  width: 100vw !important;
  height: 100vh !important;
}

.flatfile_iFrameContainer {
  height: 100% !important;
}

.flatfile-close-button {
  margin-right: 10px !important;
  margin-top: 10px !important;
}
