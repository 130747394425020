@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/themes/color-themes';

.body {
  font-size: 16px;
  font-weight: $regular;
  color: $black;
  transition: $dur-small cubic-bezier(0.37, 0.36, 0.63, 0.55);

  @include themed() {
    background-color: t($fifth-combination);
  }
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 9px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 9px !important;
  border: solid 6px rgba($gray-dark, 0.6) !important;
  background-clip: content-box !important;
  transition: $dur-small $ease !important;
  background-color: unset !important;
  border-left-color: rgba($gray-dark, 0.6) !important;
  border-bottom-color: rgba($gray-dark, 0.6) !important;
  border-right-color: rgba($gray-dark, 0.6) !important;
  border-top-color: rgba($gray-dark, 0.6) !important;
  border-left-width: 0.25rem;
  border-bottom-width: 0.25rem;
  border-right-width: 0.25rem;
  border-top-width: 0.25rem;
  border-left-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-left-radius: 0.5625rem;
  border-bottom-right-radius: 0.5625rem;
  border-top-right-radius: 0.5625rem;
  border-top-left-radius: 0.5625rem;

  &:hover {
    border: solid 6px rgba($gray-dark, 0.3) !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.orange-text {
  color: $orange;
}

button,
.ui-button,
a {
  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
    i {
      opacity: 0.7;
    }
  }
}

.warning-text {
  i {
    margin-right: 5px;
    color: $orange;
  }
}