@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.emailings {
  padding: 20px;
  @include media-breakpoint-down(sm) {
    padding: 0px 0 20px;
    .table-filters-wrap {
      margin-bottom: 10px;
      padding-right: 10px;
    }
    .table-filters-input {
      .ui-input__input {
        padding-right: 26px;
        border-radius: 3px;
        &::placeholder {
          font-size: 14px;
        }
        @include themed() {
          background-color: t($first-box-shadow-combination);
          border: 1px solid t($second-border-combination);
        }
      }
      &.expanded {
        .ui-input__input {
          max-width: 140px;
        }
      }
    }
    .table-filters-item {
      .button-search {
        background: transparent;
        box-shadow: none;
        border: none;
        min-width: auto;
        padding: 0 10px;
        i {
          font-size: 14px;
          @include themed() {
            color: t($second-text-combination);
          }
        }
      }
      .table-filters-button {
        padding: 0 15px 0 5px;
        min-width: auto;
        .ui-button__content {
          font-size: 14px;
        }
        svg {
          width: 14px;
        }
      }
    }
  }
}

.sending-image-preview {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px 0;
  &.empty {
    max-width: 30%;
  }
}
