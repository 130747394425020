@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.table-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px;
  padding: 5px 5px 5px 0;
  border-radius: 3px;
  @include themed() {
    background-color: t($eleventh-combination);
    color: t($second-text-combination);
  }
  .ui-button {
    min-width: 0;
    .ui-box {
      margin-right: 3px;
    }
    i {
      font-size: 12px;
      margin-top: 1px;
    }
    .ui-button__content {
      font-size: 12px;
      font-weight: $light;
      line-height: 20px;
    }
  }
}

.table-toolbar-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-toolbar-input {
  input {
    @include themed() {
      background-color: t($first-combination);
      color: t($first-text-combination);
    }
  }
  .ui-icon {
    svg {
      cursor: pointer;
    }
  }
}
.table-toolbar-text {
  display: inline-block;
  margin-right: 5px;
  max-width: 75px;
  font-size: 12px;
  line-height: 20px;
  @include text-truncate;
  @include themed() {
    color: t($second-text-combination);
  }
}

.ui-button.table-toolbar-action-button {
  color: $purple;
  &[disabled] {
    opacity: 0.4;
    color: $dark;
  }
  &.media-outlet-filter {
    &[disabled] {
      display: none;
    }
  }
}

.toolbar-remove {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  border-radius: 3px;
  background-color: $gray-lighten;
  .toolbar-remove-text {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
  }
}
