@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.table-wrap {
  padding: 13px;
  width: 100%;
  &.table-wrap--wizard {
    padding: 0;
    .table-toolbar {
      margin: 0;
      padding: 0 5px 0 0;
    }
  }
}

.table {
  &.table--without-popup {
    .ms-DetailsHeader-cell {
      &:last-child {
        position: unset;
        width: 100% !important;
      }
    }
  }
  .ms-List-page {
    @for $i from 1 through $reapet {
      .ms-List-cell:nth-child(#{length($colors)}n + #{$i}) {
        .ui-label {
          text-transform: uppercase;
          background: nth($colors, random(length($colors)));
          color: darken(nth($colors, random(length($colors))), 70%);
        }
      }
    }
  }
  .ms-DetailsHeader {
    padding-top: 0;

    @include themed() {
      background-color: t($ninth-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }

    .ms-DetailsHeader-cellName {
      @include themed() {
        color: t($fifth-text-combination);
      }
    }

    .ms-Icon {
      color: $white;
    }
  }

  .ms-DetailsHeader-cellIsCheck {
    padding: 4px;
  }

  .ms-Check {
    border: 1px solid $dark;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;

    &.is-checked {
      &::before {
        background: $purple;
      }
    }

    &::before {
      content: '';
      border-radius: 2px;
      position: absolute;
      left: -1px;
      right: 0;
      top: -1px;
      bottom: 0;
      width: 18px;
      height: 18px;
      background: transparent;
    }

    i[data-icon-name='CircleRing'] {
      display: none;
    }

    i[data-icon-name='StatusCircleCheckmark'] {
      left: 0;
    }
  }

  .ms-DetailsRow-cellCheck {
    width: 62px;
    height: 62px;
  }

  .ms-DetailsHeader-cell {
    .ms-Icon[data-icon-name='GripperBarVertical'] {
      display: none;
    }

    &:hover {
      @include themed() {
        background-color: t($third-hover-combination);
      }

      .ms-DetailsHeader-cell {
        .ms-Icon[data-icon-name='GripperBarVertical'] {
          display: none;
        }
      }
    }

    &:last-child {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 40px;
      width: 40px !important;
      padding: 0 10px;

      @include themed() {
        border-left: 0.5px solid t($fourth-border-combination);
      }

      cursor: pointer;

      .ms-DetailsHeader-cellName {
        i {
          @include themed() {
            color: t($third-icon-combination);
          }

          color: $dark;
          font-weight: $semi-bold;
        }
      }
    }
  }

  .ms-DetailsHeader-cellSizer {
    top: 50%;
    height: 50%;
    left: 0;
    transform: translateY(-50%);

    &:after {
      opacity: 1;

      @include themed() {
        background: t($sixth-border-combination);
      }
    }
  }

  .ms-FocusZone {
    width: 100%;
  }

  .ms-DetailsRow-fields {
    width: 100%;
    padding: 10px 0 5px 0;
  }

  .ms-DetailsRow {
    min-height: 57px;

    @include themed() {
      background-color: t($ninth-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }

  .ms-DetailsRow-cell {
    @include themed() {
      color: t($fifth-text-combination);
    }

    &:last-child {
      width: 40px !important;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .ui-label {
      background-color: $light-blue;
    }
  }

  .row-header-wrap {
    padding: 15px;

    .ui-checkbox {
      padding: 6px;
    }
  }

  .is-row-header {
    padding: 10px 0 0 7px;

    @include themed() {
      color: t($fifth-text-combination);
    }
  }

  @include media-breakpoint-down(lg) {
    .ms-DetailsRow-cell {
      max-width: 190px;
    }

    .ms-DetailsHeader-cell {
      max-width: 190px;
    }
  }

  @include media-breakpoint-down(md) {
    .ms-DetailsRow-cell {
      max-width: 180px;
    }

    .ms-DetailsHeader-cell {
      max-width: 180px;
    }
  }

  @include media-breakpoint-down(sm) {
    .ms-DetailsRow-cell {
      max-width: 160px;
    }

    .ms-DetailsHeader-cell {
      max-width: 160px;
    }
  }
}

.table--list {
  .table .ms-DetailsRow-cell:last-child {
    width: 80px !important;
  }

  .table .ms-DetailsHeader-cell:last-child {
    max-width: 80px;
    width: 80px !important;
    top: 5px;
    border-left: 0;
  }
  .table .ms-DetailsHeader {
    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }
}

.table-actions {
  .ui-button {
    min-width: 1px;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

span.link-button {
  font-weight: $regular;
  color: $purple !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
