@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';
@import 'sass/abstracts/mixins';

.saved-searches-wrap {
  position: relative;
}

.saved-searches-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  @include themed() {
    background-color: t($fifth-combination);
  }
  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 280;
  }
}

.saved-searches-content {
  position: relative;
  padding: 0 18px;
}

.saved-searches-inputs {
  display: flex;
  align-items: center;
}

.saved-searches-title {
  font-size: 16px;
  line-height: 24px;
  @include themed() {
    color: t($seven-text-combination);
  }
  @include media-breakpoint-down(sm) {
    @include themed() {
      color: t($seven-text-combination);
    }
  }
}
.saved-searches-find {
  .ui-icon {
    svg {
      cursor: pointer;
    }
  }
}
.saved-searches-filter {
  display: flex;
  align-items: center;
  .ui-icon {
    width: 20px;
    height: auto;
  }
  .filter-button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: 0;
    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
  }
  .ui-popup__content__content {
    padding: 10px 0;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  }
  .ui-list__itemheader {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ui-menu__itemwrapper {
    a {
      padding: 6px 12px;
      font-size: 14px;
      line-height: 20px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .ui-list__item {
    min-height: 35px;
  }
  .ui-button__content {
    font-size: 14px;
    line-height: 19px;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  @include media-breakpoint-up(xl) {
    .ui-menu__itemwrapper {
      &:hover {
        background-color: $gray-lighten-3;
      }
    }
    .ul-list__itemheader {
      &:hover {
        color: $dark;
      }
    }
    .filter-button {
      &:hover {
        background-color: $transparent;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .saved-searches-filter-icon {
      display: flex;
      align-items: center;
      height: auto;
      margin-right: 20px;
      span {
        font-size: 14px;
        font-weight: 600;
        @include themed() {
          color: t($second-text-combination);
        }
      }
      .ui-icon {
        display: block;
        width: 16px;
        svg {
          width: 14px;
          @include themed() {
            fill: t($second-icon-combination);
          }
        }
      }
    }
  }
}

.saved-searches-search-input {
  &.expanded {
    .ui-input__input {
      max-width: 230px;
      border-radius: 3px;
      font-size: 16px;
    }
  }
  .ui-input__input {
    max-width: 170px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    transition: $dur $ease;
    @include themed() {
      background-color: t($sixth-combination);
      color: t($eight-text-combination);
      border: 1px solid t($second-border-combination);
    }
  }
  ::placeholder {
    @include themed() {
      color: t($eight-text-combination);
    }
  }
  .ui-input__icon {
    cursor: pointer;
    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
  }
  i {
    font-size: 14px;
    transform: rotate(270deg);
    @include themed() {
      color: t($second-icon-combination);
    }
  }
  @include media-breakpoint-down(sm) {
    .ui-input__input {
      width: 24px;

      @include themed() {
        background-color: transparent;
        border: none;
      }
      padding: 5px 13px;
      position: relative;
      z-index: 1;
    }
    .ui-input__icon {
      z-index: 0;
    }
    &.expanded {
      .ui-input__input {
        width: 130px;
        border-radius: 3px;
        padding-right: 26px;
        @include themed() {
          background-color: t($first-box-shadow-combination);
          border: 1px solid t($second-border-combination);
        }
      }
      .ui-input__icon {
        z-index: 2;
      }
    }
  }
}

.saved-searches-cards {
  margin: 0 -3px;
  z-index: 180;
  min-height: 250px;
  .card {
    @include themed() {
      background-color: t($sixth-combination);
    }
    border-radius: 4px;
    box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
    height: 83%;
    width: 97%;
    padding: 8px 12px;
    margin: 0 auto;
    transition: $dur-small $ease;
    z-index: 10;
    &.active {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
        width: 100%;
        background-color: $light-green;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
  .card-icons {
    display: flex;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 100;
    @include themed() {
      background-color: t($third-hover-combination);
    }

    .card-delete-wrap {
      margin-right: 2px;
    }
    .ui-button {
      min-width: auto;
      margin-right: 4px;
      svg {
        @include themed() {
          fill: t($second-icon-combination);
        }
      }
    }
  }
  .ui-card__body {
    height: 100%;
    margin-bottom: 0;
  }
  .card-text__wrap {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include themed() {
      color: t($second-text-combination);
    }
    span:not(.card-text) {
      margin-right: 3px;
      font-size: 12px;
      line-height: 20px;
      @include themed() {
        color: t($eight-text-combination);
      }
    }
    @include media-breakpoint-down(sm) {
      max-width: 95%;
    }
    @include media-breakpoint-down(sm) {
      max-width: 95%;
    }
  }
  .card-text {
    margin: 0;
    max-width: 80%;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
    font-weight: $bold;
    word-break: break-all;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .card-avatars-wrap {
    width: calc(100% + 24px);
    border-top: 1px solid $gray-lighten;
    margin-left: -12px;
    margin-top: auto;
    position: relative;
    min-height: 32px;
  }

  .card-avatars {
    position: absolute;
    left: 15px;
    top: 0;
    padding-top: 7px;
    @for $i from 1 through $reapet {
      .ui-avatar:nth-child(#{length($colors)}n + #{$i}) {
        .ui-label {
          font-size: 8px;
          background: nth($colors, random(length($colors)));
          color: darken(nth($colors, random(length($colors))), 70%);
        }
      }
    }
    .ui-avatar {
      position: relative;
      margin-left: -5px;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        width: 25px;
        height: 25px;
        background-color: transparent;
        border-radius: 50%;
      }
    }
  }
  .card-input {
    position: relative;
    .ui-input {
      width: 100%;
    }
    .ui-input__input {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      border-radius: 3px;
      padding: 5px 5px 5px 12px;
      border-bottom: 3px solid $purple;
      background-color: $gray-lighten-3;
      min-height: 50px;
      color: $gray-dark;
      div {
        max-width: 80%;
        @include text-truncate;
      }
    }
    .card-input-actions {
      position: absolute;
      right: -15px;
      top: 55px;
      display: inherit;
      i {
        font-size: 18px;
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .card {
      &:hover {
        @include themed() {
          background-color: t($third-hover-combination);
        }
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.saved-searches-button {
  text-align: center;
  .button-load-more {
    box-shadow: 0 4px 4px rgba($black, 0.05);
    @include themed() {
      background-color: t($tenth-combination);
      border: 1px solid t($fifth-border-combination);
    }
    .ui-button__content {
      font-size: 14px;
      line-height: 19px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
}

.card-background-wrap {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 18px;
  width: 100%;
  .card-background {
    width: 31.8%;
    display: block;
    border-radius: 4px;
    height: 97px;
    margin: 0 7px 23px 7px;
    box-shadow: 0px 0.8px 1.8px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
    @include themed() {
      background-color: t($first-hover-combination);
    }
  }
  @include media-breakpoint-down(lg) {
    .card-background {
      width: 31.3%;
    }
  }
  @include media-breakpoint-down(md) {
    .card-background {
      width: 47%;
    }
  }
  @include media-breakpoint-down(sm) {
    top: 0;
    .card-background {
      width: 100%;
      height: 98px;
      margin: 0 7px 22px 7px;
    }
  }
}

.saved-searches-empty {
  min-height: 270px;
  text-align: center;
  max-width: 943px;
  margin: 5px auto 50px;
  padding: 55px 20px 65px;
  border-radius: 4px;
  @include themed() {
    background-color: t($sixth-combination);
  }
  .saved-searches-empty-img {
    max-width: 80px;
    margin-bottom: 13px;
  }
  .saved-searches-empty-text {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: $gray-dark;
    text-align: center;
    @include themed() {
      color: t($third-text-combination);
    }
    &.text-bold {
      font-weight: $bold;
      @include media-breakpoint-up(md) {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 5px 20px 50px;
    padding: 30px 20px 30px;
    min-height: auto;
  }
}

.bottom-popup-icon-wrap {
  display: block;
  @include media-breakpoint-down(lg) {
    display: block;
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 80;
    .bottom-popup-icon {
      border: 0;
      margin: 0;
      outline: none;
      background: transparent;
      background-color: transparent;
      box-shadow: none;
      padding: 6px 8px;
      min-width: unset;
      i {
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    right: 10px;
  }
}

.popup-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border: 1px solid $gray-light;
  background-color: $white;
  z-index: 200;
  .popup-list {
    list-style: none;
    padding-left: 10px;
    margin: 0;
    i {
      font-size: 13px;
      color: $dark;
      margin-right: 15px;
    }
  }
  .popup-text {
    font-size: 13px;
    color: $dark;
    line-height: 20px;
  }
  .popup-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.panel-saved-searches {
  @include media-breakpoint-down(sm) {
    .saved-searches-filter {
      max-width: 100%;
    }
    .filter-button {
      .ui-button__content {
        display: block;
      }
      .ui-box {
        margin-right: 8px;
      }
    }
  }
}
