@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .ms-Panel-main {
    @include themed() {
      background-color: t($seventh-combination);
    }
  }

  .ms-Panel-navigation {
    @include themed() {
      background-color: t($seventh-combination);
    }

    .ms-Button {
      &:hover {
        @include themed() {
          background-color: t($third-combination);
        }

        i {
          color: $purple;
        }
      }

      i {
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }
  }

  .ms-Panel-content {
    padding-left: 0;
    padding-right: 0;
  }

  .ms-Panel-commands {
    margin: 10px 0 0 0;
    position: unset;
    padding: 0;
  }

  .ms-Panel-scrollableContent {
    margin-right: -7px;
    flex-grow: 1;
  }

  .ms-Panel-contentInner {
    overflow: hidden;
  }

  @include media-breakpoint-down(sm) {
    .ms-Panel-commands {
      margin: 4px 0 0 0;
    }
  }
}
