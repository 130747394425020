@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-activities-wrap {
    padding: 15px 18px;

    .profile-panel-activities-button-container {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ui-accordion__title {
    @include themed() {
      background-color: t($eleventh-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
  }

  .profile-panel-activites-dropdowns {
    .ui-dropdown {
      background-color: $transparent;

      .ui-button,
      .ui-box {
        span {
          font-weight: 400;
          font-size: 12px;
        }
        @include themed() {
          color: t($second-text-combination);
        }
      }

      .ui-dropdown__container {
        @include themed() {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          border: none;
        }
      }

      .ui-list {
        @include themed() {
          background-color: t($sixth-combination);
        }
      }

      .ui-dropdown__item {
        background-color: $transparent;
        &:hover {
          @include themed() {
            background-color: t($second-combination);
          }
        }
      }

      .ui-dropdown__item__header {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }

  .profile-panel-activities-button {
    display: flex;
    justify-content: flex-end;
    .ui-button {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      @include themed() {
        color: t($seven-text-combination);
      }
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .button-filter-wrap {
    display: flex;
    .ui-button {
      box-shadow: none;
      border: none;
      background-color: transparent;
      margin-left: auto;
    }
  }

  .profile-panel-activities-intro {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    padding: 8px 11px 8px 6px;
    position: relative;
    z-index: 3;
    @include themed() {
      background-color: t($eleventh-combination);
    }
  }
  .profile-panel-activities-label,
  .profile-panel-activities-list {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .profile-panel-activites-dropdowns {
    position: relative;
    &:focus-within {
      border: none;
    }
  }
  .profile-panel-activities-dropdown-wrap {
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    display: none;
    min-width: 177px;
    position: absolute;
    right: -12px;
    @include themed() {
      background-color: t($sixth-combination);
    }
    &.active-dropdown {
      display: block;
    }
    @include media-breakpoint-down(sm) {
      min-width: auto;
    }
  }
  .profile-panel-activites-label {
    font-weight: $semi-bold;
    display: block;
    margin-bottom: 15px;

    @include themed() {
      color: t($second-text-combination);
    }
  }

  .profile-panel-activities-list {
    .ui-list__item {
      position: relative;
      margin-bottom: 10px;
      padding: 0 15px;
    }

    .ui-list__itemmedia {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      padding-top: 20px;
    }

    .ui-avatar__icon {
      background: transparent;

      i {
        font-size: 16px;
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }

    .ui-list__item__main {
      margin-left: 68px;
      margin-top: 23px;
      padding: 6px 10px;
      border-radius: 3px;
      cursor: pointer;
      transform: $dur-medium $ease;
      @include themed() {
        background-color: t($twelve-combination);
      }

      &::after {
        content: '';
        position: absolute;
        left: 64px;
        top: 10px;
        width: 1px;
        height: 100%;
        background-color: $silver-dark;
      }

      &::before {
        content: '';
        position: absolute;
        left: 61.5px;
        top: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $purple;
      }

      &:hover {
        background-color: $purple-lighten;
      }
    }

    .ui-list__itemheadermedia {
      position: absolute;
      left: 10px;
      top: 0;

      .list-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .list-content-date,
      .list-content-time {
        font-weight: 600;
        font-size: 10px;
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }

    .ui-list__itemheader {
      position: absolute;
      top: -4px;
      left: 81px;

      @include themed() {
        color: t($second-text-combination);
      }
    }

    .list-content-item {
      display: block;
      line-height: 20px;
      color: $dark;
      &.bold-text {
        font-weight: $bold;
        font-size: 14px;
      }
    }
  }

  .profile-panel-activities-dropdown {
    .icon {
      display: none;
    }
    .ms-Button {
      padding: 0 7px;
    }

    .ms-Button-label {
      text-align: initial;
    }

    .ms-Icon {
      font-weight: $regular;
    }

    li {
      cursor: pointer;
    }
  }
  .profile-panel-dropdown-name {
    display: block;

    @include themed() {
      color: t($second-text-combination);
    }
  }
  .profile-panel-dropdown-button {
    font-weight: $regular;
    @include themed() {
      color: t($second-text-combination);
      background-color: transparent;
      width: 100%;
      border: none;
    }

    i {
      @include themed() {
        color: t($second-icon-combination);
      }
    }

    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
    span {
      font-weight: $regular;
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(md) {
    .profile-panel-popup-button {
      display: flex;
      flex-flow: row-reverse;
      padding: 0 7px;
      justify-content: space-between;
      max-width: 160px;
      width: 100%;
      border: 0;
      box-shadow: none;
      background-color: $gray-lighten-1;

      .ui-box {
        margin-right: 0;
      }
    }

    .activities-date-dialog-wrap {
      height: calc(100% - 25px);
      position: absolute;
      top: 25px;
      left: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @include themed() {
        background-color: t($second-combination);
      }
      .ui-form {
        height: unset;
        padding: 0 20px;
      }

      .ms-TextField-fieldGroup {
        border: 0;
        background-color: $gray-light;
      }

      .activities-date-dialog-intro {
        padding: 20px;
        border-bottom: 1px solid $gray;
      }
      .activities-date-dialog-intro-action {
        svg {
          @include themed() {
            fill: t($second-text-combination);
          }
        }
      }
      .activities-date-dialog-intro-title {
        font-size: 17px;
        font-weight: $semi-bold;
        position: absolute;
        left: 50%;
        top: 15px;
        transform: translateX(-50%);
        margin: 0;
        @include themed() {
          color: t($second-text-combination);
        }
      }
      .activities-date-label {
        @include themed() {
          color: t($second-text-combination);
        }
      }
      .activities-date-buttons {
        padding: 0 20px;

        .ui-button {
          width: 100%;
          max-width: 100%;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }

      .activities-date-actions {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: flex;
      }

      .activities-date-cancel-button {
        margin-right: 15px;

        .ui-button {
          @include themed() {
            background-color: t($tenth-combination);
            border: 1px solid t($fifth-border-combination);
            color: t($second-text-combination);
          }
        }
      }
    }
  }
  @media screen and (max-width: 320px) {
    .profile-panel-activities-dropdown {
      max-width: 150px;
    }
  }
}
.activities-date-dialog-content {
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
.activities-date-wrap {
  width: 177px;
  padding: 20px;
  height: auto;

  @include themed() {
    background-color: t($sixth-combination);
  }

  .activities-form {
    justify-content: unset;
    justify-items: unset;
    height: auto;
  }

  .activities-date-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .ui-button {
      @include themed() {
        background-color: t($tenth-combination);
        border: 1px solid t($fifth-border-combination);
        color: t($second-text-combination);
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .activities-date-datepickers {
    &:last-child {
      margin-top: 0;
    }

    .ms-TextField-fieldGroup {
      @include themed() {
        background-color: t($ninth-combination);
        color: t($second-text-combination);
      }

      i {
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }
  }

  .activities-date-label {
    @include themed() {
      color: t($second-text-combination);
    }
  }

  .activities-date-accept-button,
  .activities-date-cancel-button {
    .ui-button {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.contacts-activity-empty {
  .empty-message {
    margin: 30px auto;
  }
}
