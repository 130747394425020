@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/abstracts/helpers';
@import 'sass/themes/color-themes';

.login-not-found__text {
  max-width: 497px;
  width: 100%;
  margin: 0 auto;
  font-family: $font-default;
  color: $gray-darken;
  img {
    max-width: 236px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      max-width: 130px;
    }
  }

  h3 {
    font-size: 34px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    margin: 44px 0 20px;
    @include media-breakpoint-down(sm) {
      font-size: 28px;
      margin: 20px 0;
    }
  }

  p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    word-break: break-word;
  }
}

.login-not-found__button {
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  padding: 6px 17px 4px;
  text-decoration: none;
  transition: $dur-small $ease;
  @include themed() {
    background-color: t($fourth-text-combination);
    color: t($first-combination);
  }
  &:hover {
    background-color: $purple-light;
  }
}
