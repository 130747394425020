@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-container--lists {
    .profile-panel-name--lists {
      margin: 0;

      .profile-panel-about-column-input-name {
        font-size: 22px;
        line-height: 26px;
        color: $dark;
        margin: 0;
        margin-bottom: 10px;
      }
    }

    .profile-panel-description-lists {
      max-width: 300px;

      .profile-panel-about-column-input-name {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  .profile-panel-chart-create-wrap {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 10px 10px;

    .profile-panel-chart-create-title {
      display: block;
      margin-bottom: 5px;
      font-size: 10px;
      line-height: 12px;
      color: $dark;
    }

    .profile-panel-chart-create-info {
      display: block;

      .ui-avatar {
        margin-right: 5px;
      }
    }

    .profile-panel-chart-create-name {
      font-size: 10px;
      line-height: 12px;
      color: $dark;
    }

    .profile-panel-chart-column {
      flex: 1 1 50%;
      padding-left: 10px;

      &:last-child {
        border-left: 1px solid rgba($silver-dark, 0.5);
      }
    }
  }

  .profile-panel-chart-info-wrap {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    background: $purple-lighten;
    margin-bottom: 20px;

    .profile-panel-chart-column {
      flex: 1 1 50%;
      padding-left: 10px;
      padding-right: 15px;

      &:last-child {
        border-left: 1px solid $silver-dark;
      }
    }

    .profile-panel-chart-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .profile-panel-chart-info-title {
      font-size: 10px;
      line-height: 12px;
      max-width: 100px;
      color: $dark;
    }

    .profile-panel-chart-info-number {
      font-size: 20px;
      line-height: 20px;
      color: $dark;
    }
  }

  .profile-panel-chart-wrap {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px 20px;
    &.profile-panel-chart-table-wrap {
      padding: 10px 25px 35px;
    }
    .profile-panel-chart-title {
      font-size: 12px;
      line-height: 20px;
      display: block;
      @include themed() {
        color: t($second-text-combination);
      }
    }

    .profile-panel-chart-rate-number {
      display: block;
      margin-bottom: 5px;
      font-size: 24px;
      line-height: 24px;
      color: $purple;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }

    .profile-panel-chart {
      position: relative;
      max-width: 150px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        min-width: 119px;
      }
    }

    .profile-panel-chart-info {
      text-align: center;
      @include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-left: 5px;
      }
    }

    .profile-panel-chart-number {
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);

      @include themed() {
        color: t($second-text-combination);
      }
      @include media-breakpoint-down(sm) {
        bottom: 50%;
        transform: translate(-50%, 50%);
      }
    }
    @include media-breakpoint-down(sm) {
      overflow: hidden;
      padding: 5px 0 20px 0px;
      justify-content: space-evenly;
    }
  }
}
.profile-panel-chart-column {
  @include media-breakpoint-down(sm) {
    flex: 1 1 33%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
  }
}
.profile-panel-chart-titles {
  display: flex;
  justify-content: space-between;
  padding: 0 36px;
  margin-bottom: 5px;
  @include themed() {
    background-color: t($eleventh-combination);
  }
}
.profile-panel-chart__title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
  padding: 6px 0 8px;
  margin: 0;
  @include themed() {
    color: t($second-text-combination);
  }
}
.profile-panel-chart-table {
  width: 100%;
  @include themed() {
    color: t($second-text-combination);
  }
}
.profile-panel-chart-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 8px;
  border-bottom: 1px solid rgba(#d8d8d8, 0.5);
  &.grey {
    border-bottom: none;
    @include themed() {
      background-color: t($eleventh-combination);
    }
  }
  .profile-panel-chart-column {
    flex: 0 1 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm) {
      overflow: auto;
      max-width: 100%;
      flex: 0 1 40%;
    }
  }
  .profile-panel-chart-info-title {
    font-size: 10px;
    line-height: 12px;
    display: inline-block;
  }
  .profile-panel-chart-info-number {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -2px;
    @include media-breakpoint-down(sm) {
      letter-spacing: 0;
    }
  }
}
.emailing-report-tab {
  .emailing-report-tab {
    .profile-panel-wrap .profile-panel-chart-wrap {
      @include media-breakpoint-down(sm) {
        justify-content: space-evenly;
      }
      .profile-panel-chart-column {
        max-width: max-content;
      }
      .profile-panel-chart-info {
        margin-top: 0;
      }
    }
  }
}
