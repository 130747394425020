@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-wrap {
  .profile-panel-topic,
  .profile-panel-blacklist {
    display: flex;
    justify-content: space-between;
    padding: 15px 18px;
    &__list {
      display: block;
      .tags-actions {
        border-top: none;
        padding: 0;
      }
    }
    .tag-name {
      max-width: 230px;
    }
  }

  .profile-panel-topic-column {
    position: relative;
    max-width: 50%;
    width: 100%;
  }

  .profile-panel-topic-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 12px 0;
    @include themed() {
      color: t($second-text-combination);
    }
  }

  .button-dropdown {
    background: $white;
    border: 1px solid $sliver-light;
    border-radius: 30px;
    font-size: 12px;
    line-height: 16px;
    color: $purple;
    padding: 3px 10px;
    height: auto;
    box-shadow: none;
  }

  .tag {
    overflow: hidden;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    line-height: initial;
    height: auto;
    box-shadow: none;
    min-width: auto;
    cursor: pointer;
    @include themed() {
      border: 1px solid t($sixth-border-combination);
      color: t($fourth-text-combination);
    }
    .ui-button {
      height: 16px;
      width: 16px;
      min-width: 16px;
      color: $purple;
      position: relative;
      top: -1px;
      right: -3px;
      svg {
        height: 10px;
        width: 10px;
        @include themed() {
          fill: t($fourth-text-combination);
        }
      }
    }

    .ui-pillcontent {
      padding: 2px 8px 2px 7px;
      @include themed() {
        background-color: t($tenth-combination);
      }
    }
  }
  .tags-content-more {
    border-radius: 50%;
    .ui-pillcontent {
      padding: 0 3px;
      min-width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tag-name {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    i {
      font-size: 7px;
      font-weight: 700;
      padding: 1px 1px 0 0;
    }
  }
  .ui-popup__content {
    border-radius: 3px;
    .ui-popup__content__content {
      @include themed() {
        background-color: t($seventh-combination);
        color: t($second-text-combination);
      }
    }
    ul {
      border: none;
      padding: 0;
      a {
        padding: 12px 6px 12px 12px;
        font-size: 13.5px;
        line-height: 20px;
        display: flex;
        &.danger {
          color: $red;
        }
        .ui-menu__itemicon {
          margin: 0 5px 0 0;
        }
      }
      .ui-menu__itemwrapper {
        border: none;
      }
      .ui-menu__itemcontent {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 5px;
        word-break: break-all;
      }
      .ui-button {
        width: 14px;
        height: auto;
        margin-left: auto;
      }
      .ui-icon {
        svg {
          width: 14px;
        }
      }
    }
  }
  .tag-content,
  .et {
    font-size: 12px;
  }
  .tag-name {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
  .tags-dropdown {
    max-width: 155px;
    border-bottom: 1px solid $purple;

    .ui-input__input {
      font-size: 12px;
      line-height: 16px;
      border: 0;

      &::placeholder {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }

    .ui-dropdown__container {
      border: 0;
    }

    .ui-list {
      max-width: 155px;
      width: 100%;
      max-height: 155px;

      @include themed() {
        background-color: t($ninth-combination);
      }

      .ui-dropdown__item__header {
        @include themed() {
          color: t($second-text-combination);
        }
      }

      .ui-dropdown__item {
        background-color: $transparent;
      }
    }

    .ui-dropdown__toggle-indicator {
      right: 0;
      top: 0;
    }

    .ui-dropdown__selected-items {
      border-bottom: 1px solid transparent;
      border-radius: 0;
    }
  }

  .profile-panel-topic-tags-buttons {
    display: inline;

    .ui-button {
      height: 25px;
      min-width: 25px;

      @include themed() {
        background-color: t($ninth-combination);
        border: 1px solid t(sixth-border-combination);
      }

      svg {
        width: 10px;

        @include themed() {
          fill: t($second-icon-combination);
        }
      }

      &:hover {
        color: $purple;
      }
    }
  }

  .profile-panel-topic-tags-dropdown {
    margin-bottom: 5px;
  }
}

.profile-panel-topic-tags-dropdown {
  .ui-dropdown__selected-items {
    input {
      &::placeholder {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
  .ui-dropdown__toggle-indicator {
    svg {
      path {
        @include themed() {
          fill: t($second-text-combination);
        }
      }
    }
  }
  .ui-list {
    max-height: 93px !important;
    width: 100%;
    &.ol {
      padding: 2px;
    }
    .ui-dropdown__item {
      cursor: pointer;
    }
  }
}
