@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.search-wrap {
  position: relative;
  max-width: 990px;
  padding: 0 20px;
  margin: 0 auto;
  transition: $dur-small cubic-bezier(0.37, 0.36, 0.63, 0.55);
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    margin-top: 50px;
    &.search-popup--active {
      .search-popup {
        visibility: visible;
      }
      .search-input {
        max-width: 100%;
        left: 0;
        bottom: unset;
        top: -172px;
        transform: unset;
        padding: 0;
      }
      .ui-input__input {
        padding: 15px 20px;
        box-shadow: none;
        border: 1px solid $transparent;
        border-bottom: 1px solid #d1d1d1;
        margin-top: 7px;
        border-radius: 0;
      }
      .ui-input__icon {
        i {
          font-size: 14px;
          top: 57%;
        }
      }
      .ui-segment {
        box-shadow: none;
        top: 21px;
      }
      .search + .search-help {
        display: none;
      }
    }
  }
}

.search-input {
  max-width: 996px;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  left: 50%;
  bottom: -23px;
  transform: translateX(-50%);
  z-index: 180;
  .ui-box {
    width: 100%;
    &.ui-input__icon {
      width: unset;
      cursor: pointer;
    }
  }
  .ui-input__input {
    padding: 14px 22px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border: 0;
    @include themed() {
      background-color: t($sixth-combination);
      color: t($eight-text-combination);
    }
  }
  input::placeholder {
    @include themed() {
      color: t($third-text-combination);
    }
  }
  .ui-icon {
    width: 18px;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
  .ui-input__icon {
    .icon-cancel {
      cursor: pointer;
    }
    i {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(270deg);
      font-size: 18px;
      @include themed() {
        color: t($second-icon-combination);
      }
    }
    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
  }
  @include media-breakpoint-up(sm) {
    transition: $dur-small $ease-in;
  }
  @include media-breakpoint-down(sm) {
    bottom: 26px;
  }
}

.dropdown {
  max-width: 990px;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 29px;
  border-radius: 3px;
  z-index: 100;
  transition: $dur-small $ease-in;
  .dropdown-wrap {
    width: 100%;
    box-shadow: 0px 0.6px 1.8px rgba($black, 0.11), 0px 3.2px 7.2px rgba($black, 0.13);
  }
  .ui-list__item {
    padding: 10px;
    .ui-list__itemheader {
      font-size: 14px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    &:focus {
      border: 0;
      outline: 0;
    }
  }
  .ui-avatar {
    width: 30px;
    height: 30px;
  }
  @include media-breakpoint-up(xl) {
    .ui-list__item {
      &:hover {
        @include themed() {
          background-color: t($second-hover-combination);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.empty-search {
  position: relative;
  border: 0;
  padding: 100px;
  min-height: 400px;
  box-shadow: none;
}

.empty-search-wrap {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  .empty-search-image {
    margin-bottom: 15px;
  }
  @include media-breakpoint-up(sm) {
    @media screen and (max-height: 630px) {
      top: 40px;
      transform: unset;
      .empty-search-image {
        max-width: 100px;
      }
    }
    @media screen and (max-height: 530px) {
      top: 20px;
    }
    @media screen and (max-height: 480px) {
      height: 150px;
    }
  }
}

.empty-search-text {
  display: block;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  @include themed() {
    color: t($eight-text-combination);
  }
  &.text-bold {
    font-weight: $bold;
    @include media-breakpoint-up(md) {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
}

.dropdown-segments {
  margin: 0;
  padding: 0;
  height: auto;
  max-height: 350px;
  overflow: auto;
  @include themed() {
    background-color: t($eighth-combination);
  }
  .ui-segment {
    @include themed() {
      background-color: t($eighth-combination);
    }
    .ui-list__itemcontent {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .ui-list__item {
      &:hover {
        @include themed() {
          background-color: t($third-combination);
          color: t($second-text-combination);
        }
        .ui-list__itemcontent {
          @include themed() {
            color: t($second-text-combination);
          }
        }
      }
    }
    &.empty-search {
      min-height: 340px;
    }
    ul {
      @for $i from 1 through $reapet {
        .ui-list__item:nth-child(#{length($colors)}n + #{$i}) {
          .ui-label {
            font-weight: 700;
            text-transform: uppercase;
            background: nth($colors, random(length($colors)));
            color: darken(nth($colors, random(length($colors))), 70%);
            @include media-breakpoint-down(sm) {
              font-size: 10px;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .ui-list__itemmedia {
        @include media-breakpoint-down(sm) {
          margin-right: 10px;
        }
      }
    }
  }
  .ui-list__itemheader {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  @media screen and (max-height: 800px) {
    height: auto;
    max-height: 350px;
  }
  @media screen and (max-height: 630px) {
    height: auto;
    max-height: 250px;
  }
  @media screen and (max-height: 530px) {
    height: auto;
    max-height: 200px;
  }
  @media screen and (max-height: 480px) {
    height: auto;
    max-height: 150px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0;
    height: 70vh;
    max-height: initial;
  }
}

div.dropdown-segment {
  box-shadow: none;
  border: 0;
  padding: 12px 0 5px 24px;
  @include themed() {
    background-color: t($eighth-combination);
  }
  @include media-breakpoint-up(md) {
    margin-right: 20px;
    &:last-child {
      margin-bottom: 17px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 5px 0px 7px;
  }
}

div.dropdown-show-all-results-wrap {
  position: relative;
  padding: 8px 24px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  @include themed() {
    background-color: t($fifth-combination);
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 54px;
  }
}

.dropdown-show-all-results {
  display: flex;
  align-items: center;
  .dropdown-search-icon-wrap {
    margin-right: 15px;
  }
  .dropdown-search-icon {
    font-size: 14px;
    font-weight: $bold;
    transform: rotate(270deg);
    @include themed() {
      color: t($second-icon-combination);
    }
  }
  .search-help--segments {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    .search-help--segments {
      display: block;
      top: 40px;
      right: 20px;
      z-index: 180;
    }
  }
}

.dropdown-show-all-results-text {
  font-size: 12px;
  line-height: 20px;
  margin-right: 12px;
  @include themed() {
    color: t($second-text-combination);
  }
  &.text-bold {
    font-weight: $bold;
  }
}

.dropdown-intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include themed() {
    border-bottom: 1px solid rgba(t($third-border-combination), 0.5);
  }
  padding-bottom: 12px;
  margin-bottom: 10px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 5px;
    padding: 0 20px 12px;
  }
}

.dropdown-intro-text {
  font-size: 12px;
  line-height: 20px;
  @include themed() {
    color: t($second-text-combination);
  }
}

.dropdown-intro-link {
  font-size: 12px;
  line-height: 20px;
  font-weight: $semi-bold;
  text-decoration: none;
  cursor: pointer;
  @include themed() {
    color: t($fourth-text-combination);
  }
}

.search-help {
  position: absolute;
  right: 20px;
  top: 33px;
  font-size: 12px;
  line-height: 16px;
  font-weight: $semi-bold;
  @include themed() {
    color: t($third-text-combination);
  }
  text-decoration: none;
  transition: $dur-small $ease-in;
  @include media-breakpoint-up(xl) {
    &:hover {
      color: $purple;
    }
  }
  @include media-breakpoint-down(sm) {
    right: 21px;
    top: -20px;
  }
}

.search-popup {
  position: absolute;
  left: 0;
  top: -209px;
  width: 100%;
  visibility: hidden;
  overflow: hidden;
  @include themed() {
    background-color: t($eighth-combination);
  }
  @include media-breakpoint-down(sm) {
    overflow: unset;
    .back-button-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 8px 20px;
      z-index: 200;
      box-shadow: 0px 0.3px 0.9px rgba($black, 0.14), 0px 1.6px 3.6px rgba($black, 0.12);
      @include themed() {
        color: t($second-text-combination);
        background-color: t(sixth-combination);
      }
    }
    .back-button {
      position: relative;
      border: 0;
      outline: 0;
      background: transparent;
      .back-button-text {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        padding-left: 20px;
        @include themed() {
          color: t($second-text-combination);
        }
      }
      i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(225deg);
        @include themed() {
          color: t($second-icon-combination);
        }
      }
    }
    .search-popup-segments {
      margin-top: 80px;
      @include media-breakpoint-down(sm) {
        height: 100%;
        @include themed() {
          background-color: t($eighth-combination);
        }
        .dropdown-segments-wrap {
          height: 100vh;
        }
      }
    }
    .ui-list__item {
      padding: 0 20px 0 28px;
    }
  }
}
