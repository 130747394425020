@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.wizard-panel-breadcrumbs-wrap {
  position: fixed;
  height: fill-available;
  padding: 0 20px 0 30px;
  width: 175px;

  &::after {
    content: '';
    position: absolute;
    right: -20px;
    top: -20px;
    height: 100%;
    width: 2px;
    display: block;

    @include themed() {
      border-right: 1px solid t($sixth-border-combination);
    }
  }

  .ui-breadcrumb__item {
    display: flex;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 27px;
    }

    &.active {
      .wizard-panel-breadcrum-title {
        font-weight: $semi-bold;
      }
    }

    &:not(:last-child) {
      .wizard-panel-breadcrumb-button::before {
        content: '';
        position: absolute;
        left: 8px;
        top: 20px;
        width: 1px;
        height: 15px;
        @include themed() {
          background-color: t($fourteenth-combination);
        }
      }
    }
  }

  .wizard-panel-breadcrumb-button {
    position: relative;
    min-width: 13px;
    height: 13px;
    background: transparent;
    box-shadow: none;
    border: 0;
    padding: 0 0 0 15px;
    max-width: 150px;
    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      @include themed() {
        border: 1px solid t($second-text-combination);
      }
    }

    .ui-button__content {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;

      @include themed() {
        color: t($second-text-combination);
      }

      margin-left: 10px;
    }

    &:hover {
      background-color: $transparent;
    }

    &[disabled] {
      .ui-button__content {
        font-weight: $regular;
      }

      &::after {
        border: 1px solid $dark-1;
        background-color: $transparent;
        @include themed() {
          border: 1px solid t($fourteenth-combination);
        }
      }
      &.completed {
        &::after {
          border-color: $green;
        }
      }
      &.active {
        &::after {
          border-color: $purple;
        }
      }
    }

    &.active {
      .ui-button__content {
        font-weight: $semi-bold;
      }
      &::after {
        background-color: $purple;
        border: 1px solid $purple;
      }
    }

    &.completed {
      .ui-button__content {
        font-weight: $semi-bold;
      }
      &::after {
        background-color: $green;
        border: 1px solid $green;
      }
    }
  }
}
