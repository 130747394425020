@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/animations';
@import 'sass/themes/color-themes';

.bottom-popup-overlay {
  position: fixed;
  z-index: 2000000;
}

.bottom-popup-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 1px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  animation: custom-bottom-popup 0.5s;
  @include themed() {
    background-color: t(sixth-combination);
  }
}

.bottom-popup-list {
  list-style: none;
  padding-left: 0;
  padding: 26px 22px;
}

.profile-panel-menu-item {
  &:not(:last-child) {
    margin-bottom: 13px;
  }
}

.bottom-popup-item {
  font-size: 17px;
  line-height: 22px;
  margin: 0 10px;
}

.bottom-popup-items {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.bottom-popup-content {
  display: flex;
}

.bottom-popup-title {
  font-size: 17px;
  font-weight: 400;
  @include themed() {
    color: t($second-text-combination);
  }
}

.bottom-popup-icon {
  margin-right: 17px;
  i {
    font-size: 18px;
    @include themed() {
      color: t($second-icon-combination);
    }
  }
  &:has(i[data-icon-name='Delete']) {
    i {
      color: $red;
    }
    ~ .bottom-popup-title {
      color: $red;
    }
  }
}

.bottom-popup-line {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  height: 2px;
  width: 30px;
  border-radius: 100px 100px 0px 0px;
  @include themed() {
    background-color: t($second-icon-combination);
  }
}
