@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel {
  .action-bar-wrap .action-bar-buttons {
    background-color: transparent;
    padding: 0 5px 0 0;
  }
  .bottom-popup-icon i {
    font-size: 15px;
  }
}
.profile-panel-wrap {
  .profile-panel-intro {
    position: relative;
    padding: 0 15px 15px 15px;
    display: flex;
    &.sending-profile-panel {
      .profile-panel-content {
        flex: 1;
      }
    }
    @include themed() {
      border-bottom: 1px solid t($sixth-border-combination);
    }

    .profile-panel-topic-tags {
      margin: 10px 0;
    }

    .remove-tag {
      position: absolute;
      right: 0;
      top: -2px;

      svg {
        height: 12px;
      }
    }

    .profile-panel-avatar {
      width: 90px !important;
      height: 90px !important;
      cursor: pointer;
    }
  }

  .profile-panel-menu {
    position: absolute;
    top: -45px;
    right: 40px;
    z-index: 250;

    .ui-button {
      position: absolute;
      top: 13px;
      right: 6px;
      border-radius: 2px;

      @include themed() {
        color: t($second-text-combination);
      }

      &:hover {
        @include themed() {
          background-color: t($third-combination);
        }

        svg {
          @include themed() {
            fill: $purple;
          }
        }
      }

      svg {
        @include themed() {
          fill: t($second-icon-combination);
        }
      }
    }

    .ui-menubutton__menu {
      border: 0;
      box-shadow: 0px 0.6px 1.8px rgba($black, 0.11), 0px 3.2px 7.2px rgba($black, 0.13);
      border-radius: 4px;
    }
  }

  .profile-panel-menu-item {
    display: flex;
    align-items: center;

    &.danger {
      i {
        color: $red;
      }

      .profile-panel-menu-name {
        color: $red;
      }
    }

    i {
      margin-right: 10px;
    }

    .profile-panel-menu-name {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .profile-panel-menu {
      .ui-button {
        top: 15px;
      }
    }

    .profile-panel-image-dialog-wrap {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $dark;
      z-index: 2000000;
    }

    .profile-panel-image-actions {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }

    .profile-panel-image-action {
      svg {
        color: $white;
      }
    }

    .profile-panel-img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      img {
        width: 100%;
      }
    }

    .bottom-popup-icon-wrap {
      top: 15px;
      right: 7px;
    }
  }

  @include media-breakpoint-down(sm) {
    .profile-panel-name {
      margin-bottom: 0;
    }

    .profile-panel-avatar {
      width: 28px !important;
      height: 28px !important;
      border-radius: 50%;
    }

    .profile-panel-image {
      margin-right: 20px;
    }
  }
}

.panel-intro {
  position: relative;
  display: flex;
  padding: 0 15px 15px 15px;

  @include themed() {
    border-bottom: 1px solid t($sixth-border-combination);
  }

  .profile-panel-topic-tags {
    margin: 10px 0;
  }

  .remove-tag {
    position: absolute;
    right: 0;
    top: -2px;

    svg {
      height: 12px;
    }
  }

  .profile-panel-avatar {
    width: 90px !important;
    height: 90px !important;
    cursor: pointer;
  }
}

.panel-intro-content {
  width: 100%;

  &.editable {
    .panel-intro-content__bottom {
      justify-content: end;
    }
    .editable-fields-row {
      display: block;
      height: 100%;
      max-height: 100%;
      max-width: 420px;
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .editable-fields-value {
      display: block;
      max-width: 85%;
      padding: 3px 0;
    }

    .panel-intro-title {
      max-width: 420px;
      margin: 0;

      .editable-fields-value {
        font-size: 22px;
        font-weight: $bold;
        line-height: initial;
      }

      .editable-fields-actions {
        top: 4px;
      }

      .editable-fields-input .ui-input__input {
        font-size: 22px;
        font-weight: $semi-bold;
        line-height: 26px;
      }
    }

    .panel-info-description {
      .editable-fields-value {
        font-size: 16px;
        line-height: 18px;
      }

      .editable-fields-input .ui-input__input {
        font-size: 16px;
        line-height: 18px;

        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
}
.panel-intro-content__bottom {
  display: flex;
  justify-content: space-between;
  .alert-msg {
    svg {
      path {
        fill: $purple-dark;
      }
    }
  }
  .ui-button {
    box-shadow: none;
    border: none;
    min-width: auto;
    padding: 0 10px;
    cursor: none;
    pointer-events: none;
  }
}
.alert-mgs__title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
  svg {
    margin-right: 5px;
    path {
      fill: $purple-dark;
    }
  }
}
.panel-avatar-holder {
  position: relative;
  margin-right: 15px;
}
.profile-panel-avatar-wrap {
  .ui-label {
    text-transform: uppercase;
  }
}
.panel-avatar {
  position: relative;

  .ui-avatar {
    cursor: pointer;
  }

  .ui-label {
    background-color: $light-blue;
    color: #346c6d;
    text-transform: uppercase;
  }
}

.panel-avatar-skelet {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba($dark, 0.5);
  border-radius: 50%;
  cursor: pointer;

  .ui-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    color: $white;
    height: 20px;
    width: 20px;
  }
}

.panel-intro-info {
  margin-bottom: 10px;
}

.panel-intro-title {
  word-break: break-word;
  font-size: 22px;
  line-height: initial;
  margin: 0;
  margin-bottom: 10px;
  max-width: 320px;

  @include themed() {
    color: t($second-text-combination);
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.panel-intro-menu {
  position: absolute;
  top: -45px;
  right: 40px;
  z-index: 250;

  .ui-button {
    position: absolute;
    top: 13px;
    right: 6px;
    border-radius: 2px;
    @include media-breakpoint-down(sm) {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    &:hover {
      @include themed() {
        background-color: t($third-combination);
      }

      svg {
        @include themed() {
          fill: $purple;
        }
      }
    }

    svg,
    i {
      @include themed() {
        fill: t($second-icon-combination);
        color: t($second-icon-combination);
      }
    }
  }

  .ui-popup__content__content {
    @include themed() {
      background-color: t($seventh-combination);
      color: t($second-text-combination);
    }
  }

  .ui-menubutton__menu {
    border: 0;
    box-shadow: 0px 0.6px 1.8px rgba($black, 0.11), 0px 3.2px 7.2px rgba($black, 0.13);
    border-radius: 4px;

    @include themed() {
      background-color: t($seventh-combination);
      color: t($second-text-combination);
    }

    .ui-menu__itemwrapper {
      &:hover {
        @include themed() {
          background: t($third-combination);
        }
      }
    }
  }
}

.panel-intro-menu-item {
  display: flex;
  align-items: center;

  &.danger {
    i {
      color: $red;
      margin-top: -2px;
    }

    .profile-panel-menu-name {
      color: $red;
    }
  }

  i {
    margin-right: 5px;
  }

  .profile-panel-menu-name {
    @include themed() {
      color: t($second-text-combination);
    }
  }
}

.panel-info-description {
  display: block;
  max-width: 90%;
  font-size: 16px;
  line-height: 20px;
  word-break: break-all;
  @include themed() {
    color: t($second-text-combination);
  }
}
