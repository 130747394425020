@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-phone-dialog-wrap,
.profile-panel-phone-popup-wrap {
  .ms-Dialog-main {
    max-width: 600px;
    min-width: 600px;
    width: 100%;
  }

  .ms-Dialog-title {
    font-size: 18px;
    line-height: 24px;
    color: $dark;
  }

  .ui-radiogroup__item__indicator {
    width: 0px !important;
    margin-right: 15px;
  }

  .ui-radiogroup__item__label {
    display: block;
    width: 100%;
  }

  .profile-panel-phone-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .profile-panel-phone-item {
    flex: 1 1 33%;
  }

  .ui-radiogroup__item {
    border-bottom: 1px solid $gray-lighten-1;
  }

  .button-star {
    border: 0;
    background: $white;
    box-shadow: none;
    margin-left: 31px;

    svg {
      fill: $white;
      stroke: $dark;
      width: 11px;
      height: 100%;
    }

    &:active {
      background-color: transparent;
    }

    &.button-star--primary {
      margin-left: 31px;

      svg {
        fill: $yellow;
        stroke: $yellow;
      }
    }
  }

  .profile-panel-phone-header {
    padding: 10px 0 10px 32px;
    border-bottom: 1px solid $gray-lighten-1;
    display: flex;
    align-items: center;
  }

  .profile-panel-phone-header-item {
    flex: 1 1 33.33%;
    @include themed() {
      color: t($second-text-combination);
    }
  }

  @include media-breakpoint-down(lg) {
    .bottom-popup-wrap {
      padding: 25px 10px;
      .profile-panel-menu-item {
        display: flex;
        align-items: center;

        .bottom-popup-item-icon {
          margin-right: 15px;
          margin-left: 5px;
        }
      }
    }

    .profile-panel-phone-content {
      margin-bottom: 35px;
    }

    .profile-panel-phone-actions {
      display: flex;
      justify-content: flex-end;
    }

    .ui-button {
      margin-right: 15px;
    }

    .profile-panel-phone-item-star {
      flex: 1 1 5%;
    }
  }

  @include media-breakpoint-down(sm) {
    .profile-panel-phone-item {
      font-size: 11px;
    }
  }
}
