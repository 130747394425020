@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.sendingStatus {
  display: flex;
  .sendingStatus-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
.row-header-more-options {
  margin-left: auto;
  flex: 0 0 100px;
  margin-top: -4px;
  display: none;

  .ui-button {
    border: 0;
    background: transparent;
    box-shadow: none;
    transition: 0.2s ease;
    &[disabled] {
      opacity: 0.6;
    }
    i {
      font-size: 14px;
      font-weight: $regular;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    &.full {
      i {
        color: $yellow;
      }
    }
    &:hover {
      @include themed() {
        background-color: t($seventeen-combination);
      }
    }
  }
  .ui-menubutton {
    position: absolute;
  }
  .more-options-item {
    display: flex;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
}
.table-results {
  .profile-panel-avatar {
    margin-right: 10px;
    margin-bottom: 2px;
  }
  .ms-DetailsRow-cell {
    position: unset;
    .ui-avatar {
      display: inline-block;
      margin-right: 8px;
      text-transform: uppercase;
    }
    .ui-label {
      background-color: $light-blue;
    }
    .row-icon-star {
      margin-right: 11px;
      font-size: 16px;
      color: $yellow;
    }
  }
  .ms-List-cell {
    position: unset;
    .ms-DetailsRow {
      animation: none;
    }
    .profile-panel-menu-name {
      font-weight: 400;
    }
    &:hover {
      .ms-DetailsRow {
        @include themed() {
          background-color: t($fourth-combination);
        }
      }
      .row-header-more-options {
        display: block;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
  .table-row {
    position: relative;
    z-index: 1;
    .ms-DetailsRow.is-selected {
      background: rgb(243, 242, 241);
    }

    &.active {
      .ms-DetailsRow.is-selected {
        .row-header-wrap {
          .row-header-more-options {
            background: rgb(243, 242, 241);
          }
        }
      }
    }
  }
}

.table-results-empty-wrap {
  min-height: 250px;
  padding: 54px;
  margin-top: 10px;
  @include themed() {
    background-color: t($sixth-combination);
  }
  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
    margin: 20px;
    border-radius: 4px;
    min-height: auto;
  }
}

.table-results-empty {
  text-align: center;
}

.table-results-empty-image {
  margin-bottom: 15px;
  @include media-breakpoint-down(sm) {
    img {
      width: 90px;
    }
  }
}
.table-results-empty-text {
  display: block;
  font-weight: $bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  @include themed() {
    color: t($eight-text-combination);
  }
  &:last-child {
    font-weight: $regular;
  }
}

.sending-filter-pills-wrap {
  .filter-input-wrap {
    display: flex;
    margin-bottom: 8px;
    align-items: flex-start;
  }
  .filter-pills-wrap {
    position: relative;
    margin-right: 20px;
    flex: auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .filter-pill {
    max-width: 180px;
    width: auto;
    height: 25px;
    background: $white;
    border: 1px solid $gray-lighten-4;
    margin: auto 0.25rem;
    cursor: pointer;
    @include themed() {
      background-color: t($ninth-combination);
    }
    .filter-pill-text {
      max-width: 150px;
      padding: 2px 0px 3px 12px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      display: block;
      @include text-truncate;
    }
    svg {
      width: 10px;
      height: 10px;
      @include themed() {
        fill: t($second-text-combination);
      }
    }
    .ui-button {
      min-width: 20px;
      height: 17px;
      padding-top: 1px;
    }
    .ui-pillcontent {
      padding: 0 8px 0 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      @include themed() {
        color: t($fourth-text-combination);
      }
    }
    .filter-pill-field-name {
      text-transform: capitalize;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .filter-pills-number-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    background: $white;
    border-radius: 50%;
    border: 1px solid $gray-lighten;
    font-size: 13px;
    .filter-pills-number {
      @include align(absolute, xy);
      color: $purple;
    }
  }
  .button-filter {
    box-shadow: none;
    border: 0;
    background-color: transparent;
    height: auto;
    margin-bottom: 12px;
  }
}
