@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.profile-panel-qr-code__dialog {
  .ms-Dialog-main {
    max-width: 256px;
  }
}
.profile-panel-qr-code {
  .profile-panel-qr-code-canvas {
    margin: 0 auto;
    width: 135px;
  }
}
