@import 'sass/abstracts/variables';

.global-spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  width: 48px;
  height: 48px;

  .global-spinner {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid;
    border-color: rgba($purple, 0.15) rgba($purple, 0.25) rgba($purple, 0.35) rgba($purple, 0.5);
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}