@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.wizard-panel-finish-wrap {
  margin-bottom: 73px;
  margin-left: 196px;
  padding-left: 20px;
  .wizard-panel-finish-main-intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ui-button {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }

  .wizard-panel-finish-title {
    font-size: 14px;
    font-weight: $semi-bold;
    line-height: 24px;

    @include themed() {
      color: t($second-text-combination);
    }

    margin: 0;
    margin-bottom: 12px;
  }
  .custom-accordion-head-actions {
    button {
      @include themed() {
        color: t($sixth-text-combination);
      }
    }
  }

  .wizard-panel-finish-main-content {
    padding: 0 15px;
    margin-bottom: 15px;
  }

  .wizard-panel-finish-main-box {
    display: flex;
    @include themed() {
      color: t($first-text-combination);
    }
  }

  .wizard-panel-finish-main-label {
    flex: 1 1 45%;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .wizard-panel-finish-main-input {
    flex: 1 1 65%;
    font-size: 12px;
    &.link {
      color: $purple;
      text-decoration: none;
    }
  }
}
