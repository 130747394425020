@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/themes/color-themes';

.list-overview-info {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  &.chart-info {
    padding: 15px 10px;
    background: $purple-lighten;
    margin: 10px 0 30px;
    @include themed() {
      background: t($fifteenth-combination);
    }
    .list-overview-info-description {
      font-size: 20px;
      line-height: 20px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .list-overview-info-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .list-overview-info-description {
      margin-right: 10px;
    }
    .list-overview-info-label {
      max-width: 110px;
    }
  }
  &.chart {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
    border-top: 1px solid rgba($silver-dark, 0.5);
    .list-overview-info-column:last-child {
      border-left: none;
    }
    @include media-breakpoint-down(sm) {
      padding: 5px 0 20px 0;
    }
  }
}

.list-overview-info-column {
  flex: 1 1 50%;
  padding-left: 10px;
  @include media-breakpoint-down(sm) {
    flex: 1 1 33%;
    max-width: 35%;
    overflow: hidden;
    text-align: center;
  }
  &:last-child {
    border-left: 1px solid rgba($silver-dark, 0.5);
  }
  &.chart-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

.list-overview-info-label {
  display: block;
  font-size: 10px;
  line-height: 12px;
  @include themed() {
    color: t($second-text-combination);
  }
}

.list-overview-info-description {
  font-size: 10px;
  line-height: 12px;
  @include themed() {
    color: t($second-text-combination);
  }
}

.list-overview-info-chart-label {
  font-size: 12px;
  line-height: 20px;
  display: block;
  @include themed() {
    color: t($second-text-combination);
  }
}

.list-overview-chart-number {
  display: block;
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 24px;
  color: $purple;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

.list-overview-info-chart {
  position: relative;
  max-width: 150px;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    min-width: 119px;
  }
}

.list-overview-info-chart-number {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
  @include themed() {
    color: t($second-text-combination);
  }
  @include media-breakpoint-down(sm) {
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
}

.list-overview-info-chart-content {
  text-align: center;
  @include media-breakpoint-down(sm) {
    margin-top: -10px;
  }
}
