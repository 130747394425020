@import 'sass/abstracts/variables';
@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/animations';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.template-card-wrap {
  margin-bottom: 12px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.template-card-intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ui-menubutton {
    .ui-box {
      i {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
  }
}

.template-card {
  position: relative;
  min-width: 192px;
  min-height: 134px;
  max-width: 192px;
  max-height: 134px;
  padding: 10px;
  box-shadow: 0px 0.15px 0.45px rgba($black, 0.11), 0px 0.8px 1.8px rgba($black, 0.13);
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  @include themed() {
    background-color: t($first-combination);
  }
  &:last-child {
    margin-right: 0;
  }
}

.template-card-text {
  font-size: 14px;
  font-weight: $semi-bold;
  line-height: 20px;
  max-width: 159px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  @include themed() {
    color: t($second-text-combination);
  }
  @include media-breakpoint-down(md) {
    font-size: 12px;
    line-height: 16px;
  }
}

.template-card-image {
  text-align: center;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
}

.template-card-actions {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba($dark, 0.5);
  transition: 4s $ease;
  animation: smooth-opacity $dur;
}

.template-card-actions-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .ui-button {
    width: 100%;
  }
  .ui-button:first-child {
    margin-bottom: 10px;
  }
}

.profile-panel-menu-item {
  display: flex;
  align-items: center;
  &[disabled] {
    i {
      opacity: 0.6;
    }
  }
  &.danger {
    i {
      color: $red;
    }
  }

  i {
    margin-right: 10px;
  }

  .profile-panel-menu-name {
    @include themed() {
      color: t($second-text-combination);
    }
  }

  &.danger {
    .profile-panel-menu-name {
      color: $red;
    }
  }
}

.template-card-editable-fields-input {
  position: relative;
  flex: 1 1 100%;
  input {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .input-wrap {
    width: 100%;

    &.error {
      .ui-input__input {
        border-bottom: 2px solid $red;
      }
    }
  }

  .ui-input__input {
    background: transparent;
    border-bottom: 2px solid $purple;
    border-radius: 0;
    width: 100%;
    padding: 0 50px 0 10px;
    font-size: 12px;
  }

  .ui-input__icon {
    right: -8px;
  }
}
