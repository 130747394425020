@import 'sass/abstracts/breakpoints';
@import 'sass/abstracts/variables';
@import 'sass/abstracts/mixins';
@import 'sass/themes/color-themes';

.wizard-panel-wrap {
  .wizard-panel {
    height: calc(100% - 106px);
  }
  .ms-Panel-scrollableContent {
    height: 100%;
  }
  .ms-Panel-main {
    width: 750px;
    @include themed() {
      background-color: t($seventh-combination);
    }
  }
  &.saved-search-table {
    overflow: hidden;
    .table-results .ms-DetailsList > div {
      padding-bottom: 0;
    }
  }
  &.wider {
    .ms-Panel-main {
      width: 90%;
    }
    .wizard-panel-main {
      padding: 0;
    }
  }
  &.saved-search-panel {
    .wizard-panel-bottom {
      position: absolute;
    }
  }
  &.wizard-panel-wrap--narrow {
    .wizard-content {
      margin-left: 0;
    }
  }
  .ms-Panel-headerText {
    font-size: 13px;
    line-height: 20px;
    font-weight: $semi-bold;
    margin: 8px 0;

    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ms-Panel-commands {
    width: 100%;
    margin-top: 0;
    padding: 5px 0;

    @include themed() {
      background-color: t($seventh-combination);
      border-bottom: 1px solid t($sixth-border-combination);
    }
  }
  .ms-Panel-header {
    align-self: center;
  }
  .ms-Button {
    &:hover {
      @include themed() {
        background-color: t($third-combination);
      }

      i {
        color: $purple;
      }
    }
    i {
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .ms-Panel-content {
    padding: 0;
  }
  .wizard-panel-content {
    display: flex;
    height: 100%;
    margin: 0 0 76px 0;
  }
  .wizard-panel-sidebar {
    position: fixed;
    top: 33px;
    min-width: 196px;
    height: calc(100% - 106px);
    @include themed() {
      border-right: 1px solid t($sixth-border-combination);
    }
  }
  .wizard-panel-main {
    padding: 20px 20px 20px 0;
    width: 100%;
    height: 100%;
  }
  .wizard-panel-intro {
    margin-bottom: 50px;
    padding-top: 10px;
  }
  .wizard-panel-intro-info {
    margin-top: 15px;
    .ui-avatar {
      margin-right: 20px;
    }
  }
  .wizard-panel-intro-info-text {
    font-size: 15px;
    font-weight: $bold;
  }
  .wizard-content-audience {
    .table-wrap--wizard {
      .empty-message {
        display: none;
      }
    }
  }
  .wizard-panel-title {
    font-size: 18px;
    font-weight: $semi-bold;
    line-height: 20px;
    margin: 0;
    margin-bottom: 5px;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .wizard-panel-description {
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    font-weight: $light;
    line-height: 16px;
    @include themed() {
      color: t($sixth-text-combination);
    }
    &.text-bold {
      font-weight: $bold;
    }
  }
  .wizard-panel-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0 10px 10px 0;
    &.wizard-panel-toolbar-audience {
      justify-content: normal;
      padding: 5px 0 1px;
      font-size: 12px;
      margin-bottom: 0px;
      .action-bar-wrap {
        margin: 0;
        padding: 0;
      }
      .action-bar-selected {
        margin-right: 10px;
        font-size: 12px;
      }
      .remove-button {
        .ui-button {
          pointer-events: none;
          opacity: 0.5;
          padding: 0;
          min-width: auto;
          @include themed() {
            color: t($sixth-text-combination);
          }
          .ui-box {
            margin-right: 2px;
            i {
              font-size: 15px;
              position: relative;
              top: 0.3px;
            }
          }
          .ui-button__content {
            font-weight: 400;
            font-size: 11px;
            letter-spacing: 0.06px;
          }
        }
      }
      .action-bar-buttons__active {
        .remove-button {
          .ui-button {
            pointer-events: all;
            opacity: 1;
          }
        }
      }
      .wizard-panel-toolbar-filters {
        margin-left: auto;
      }
    }
    @include themed() {
      background-color: t($eleventh-combination);
    }
  }
  .wizard-panel-toolbar-create {
    .ui-button {
      min-width: auto;
      padding: 0 15px;
      @include themed() {
        color: t($sixth-text-combination);
      }
    }
    .ui-menubutton {
      .ui-button {
        border: 0;
        background: transparent;
        box-shadow: none;

        @include themed() {
          color: t($sixth-text-combination);
        }
        .ui-box {
          margin-right: 5px;
        }
        i {
          font-size: 8px;
          font-weight: 600;
          position: relative;
          top: 2px;
          @include themed() {
            fill: t($fourth-text-combination);
          }
        }

        .ui-button__content {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          @include themed() {
            color: t($nine-text-combination);
          }
        }
      }
    }
  }
  .wizard-panel-toolbar-filters {
    display: flex;
    margin-right: 10px;
    .wizard-panel-toolbar-filter {
      .ui-button {
        border: 0;
        background: transparent;
        box-shadow: none;
        @include themed() {
          color: t($fourth-text-combination);
        }
      }
      i {
        font-size: 10px;

        @include themed() {
          fill: t($fourth-text-combination);
        }
      }
    }
  }
  .wizard-panel-toolbar-input {
    .ui-input__input {
      @include themed() {
        background-color: t($fifth-combination);
        color: t($second-text-combination);
      }

      &::placeholder {
        @include themed() {
          color: t($sixth-text-combination);
        }
      }
    }
    svg {
      @include themed() {
        fill: t($second-icon-combination);
      }
    }
  }
  .wizard-panel-button-create {
    .ui-box {
      margin-right: 3px;
    }
    &.ui-button {
      border: 0;
      background: transparent;
      box-shadow: none;
      @include themed() {
        color: t($sixth-text-combination);
      }
      i {
        font-size: 10px;

        @include themed() {
          fill: t($fourth-text-combination);
        }
      }
      .ui-button__content {
        font-size: 12px;
        line-height: 20px;

        @include themed() {
          color: t($sixth-text-combination);
        }
      }
    }
  }
  .wizard-panel-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 16px 22px;
    display: flex;
    justify-content: space-between;
    @include themed() {
      background-color: t($eleventh-combination);
      border-top: 1px solid t($sixth-border-combination);
    }
    .ui-button {
      border-radius: 2px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      i {
        font-size: 12px;
        position: relative;
        top: 1px;
        margin-right: 5px;
      }
    }
  }
  .wizard-panel-bottom-actions {
    .ui-button:not(:last-child) {
      margin-right: 15px;
    }
  }
  &--emailing {
    .ms-Panel-main {
      width: 90%;
    }
    .wizard-panel-bottom {
      position: absolute;
      z-index: 999;
    }
    .wizard-panel-form {
      flex-direction: column;
    }
    .wizard-panel-main {
      display: flex;
      padding: 0 0 20px 0;
    }
    .ms-Panel-content {
      height: 100%;
      padding-bottom: 32px;
    }
    .ms-Panel-scrollableContent {
      overflow: hidden;
    }
    .wizard-panel-breadcrumbs-wrap {
      padding-top: 20px;
    }
  }
}

.wizard-panel-wrap--edit {
  .ms-Panel-main {
    width: 600px;
  }
  .wizard-panel-form-wrap {
    padding-left: 20px;
    margin-left: 4px;
  }
}

.wizard-panel-form-wrap {
  overflow: scroll;
  margin-bottom: 76px;
  padding: 0 20px;
  flex: 1;
  margin-left: 196px;
  .wizard-panel-form {
    display: flex;
    flex-wrap: wrap;
  }
  &.audience-form-wrap {
    overflow: auto;
  }
  &.wizard-preview-panel {
    margin-bottom: 59px;
    overflow: auto;
  }
  &.wizard-panel-form-wrap--sendings {
    flex: 1;
    padding: 0;
    overflow: auto;
    margin-bottom: 58px;
    #bee-plugin-container {
      height: 100%;
    }
  }
  .wizard-panel-input-alert {
    position: absolute;
    bottom: -12px;
    left: 0;
    font-size: 8px;
    color: $red;
  }
  .wizard-panel-input,
  .custom-dropdown {
    position: relative;
    max-width: 50%;
    width: 100%;
    margin-bottom: 20px;
    .ui-input {
      width: 95%;
    }
    .ui-input__input {
      width: 100%;
      @include themed() {
        color: t($second-text-combination);
        background-color: t($third-combination);
      }
      &::placeholder {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
    svg {
      @include themed() {
        fill: t($second-text-combination);
      }
    }
    .error-message {
      font-size: 10px;
      line-height: 16px;
      color: $red;
      position: absolute;
      left: 0;
      bottom: -17px;
    }
    .required-label {
      span {
        color: $red;
        margin-left: 3px;
        font-size: 15px;
      }
    }
  }
  .custom-dropdown {
    .dropdown-label {
      display: block;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
      width: 95%;
      @include themed() {
        color: t($sixth-text-combination);
      }
    }

    .ui-dropdown {
      width: 95%;
    }
    .ui-dropdown__container {
      width: 100%;
      position: relative;
    }
    .ui-dropdown__selected-items {
      padding-right: 0;
    }
    .ui-dropdown__item {
      cursor: pointer;
      @include themed() {
        background-color: t($sixth-combination);
      }
      &:hover,
      &:visited {
        @include themed() {
          background-color: t($fifth-combination);
        }
      }
      .ui-box {
        @include themed() {
          color: t($second-text-combination);
        }
      }
    }
    .ui-input__input {
      padding: 6.5px 27px 6.5px 11px;
      cursor: pointer;
    }
    .ui-dropdown__items-list {
      width: 100%;
      @include themed() {
        color: t($second-text-combination);
        background-color: t($sixth-combination);
      }
    }
    .ui-dropdown__item__header > div {
      cursor: pointer;
    }
  }
  .wizard-panel-checkbox {
    margin-bottom: 15px;
    .ms-Checkbox.is-checked {
      .ms-Checkbox-checkbox {
        background-color: $purple;
        border-color: $purple;
      }
    }
    .ms-Checkbox-checkbox {
      border-radius: 3px;
    }
    .ms-Checkbox-text {
      font-size: 12px;
      @include themed() {
        color: t($second-text-combination);
      }
    }
  }
  .wizard-panel-textarea {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
    span {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
      display: inline-block;
      @include themed() {
        color: t($sixth-text-combination);
      }
    }
    .ui-textarea {
      width: 93%;
      @include themed() {
        color: t($second-text-combination);
        background-color: t($third-combination);
      }
    }
  }
  .ui-input__label {
    font-size: 12px;
    line-height: 16px;
    @include themed() {
      color: t($sixth-text-combination);
    }
    margin-bottom: 5px;
  }
  .ui-label {
    background-color: $transparent;
    padding: 0;
    text-transform: uppercase;
    background-color: #d1ebde;
    color: #1c5d3b;
    .ui-box {
      font-size: 12px;
      line-height: 16px;

      @include themed() {
        color: t($sixth-text-combination);
      }
    }
  }
  .wizard-panel-dropdown {
    position: relative;
    max-width: 50%;
    width: 100%;
    margin-bottom: 20px;
    ul {
      width: 100%;
    }
    .ui-dropdown__container {
      width: 95%;
      border-color: $transparent;
      @include themed() {
        color: t($second-text-combination);
        background-color: t($third-combination);
      }
      .ui-icon {
        svg {
          path {
            @include themed() {
              fill: t($second-text-combination);
            }
          }
        }
      }
      .ui-dropdown__selecteditem {
        font-size: 12px;
        line-height: 16px;
        height: 25px;
        color: #6264a7;
        position: relative;
        z-index: 5;
        svg {
          width: 13px;
          left: 3px;
        }
      }
      .ui-dropdown__selected-items {
        height: 100%;
        position: relative;
        z-index: 1;
        padding-right: 0;
        .ui-dropdown__trigger-button {
          padding-right: 30px;
        }
      }
      .ui-dropdown__clear-indicator {
        top: 6px;
        z-index: 5;
      }
    }
    .ui-list {
      max-width: 100%;
      .ui-dropdown__item__header {
        @include themed() {
          color: t($second-text-combination);
        }
      }
      .ui-dropdown__item {
        cursor: pointer;
        @include themed() {
          background-color: t($sixth-combination);
        }
        &:hover,
        &:visited {
          @include themed() {
            background-color: t($fifth-combination);
          }
        }
      }
      @include themed() {
        background-color: t($sixth-combination);
      }
    }
    .ui-button__content {
      font-size: 11px;
      font-weight: $regular;
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .error-message {
      font-size: 10px;
      line-height: 16px;
      color: $red;
      position: absolute;
      left: 0;
      bottom: -17px;
    }

    .dropdown-label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
      display: inline-block;
      span {
        font-size: 12px;
        line-height: 16px;
        color: $red;
      }
      @include themed() {
        color: t($sixth-text-combination);
      }
    }
  }
}
.app__main {
  button {
    &.stagemode__button--active {
      background-color: $purple !important;
    }
  }
}
.wizard-accordion-wrap {
  .ui-accordion__title {
    padding: 0;
  }
  .ui-accordion__title__content-wrapper {
    display: flex;
    outline: none;
    padding: 0;
    .ui-box {
      &:last-child {
        display: block;
        width: 100%;
        outline: none;
        margin-left: -20px;
      }
    }
  }
  .wizard-accordion-heading {
    font-size: 12px;
    font-weight: $bold;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ui-accordion__title__content-wrapper[aria-expanded='true'] {
    .ui-icon {
      transform: rotate(180deg);
    }
  }
  .wizard-accordion-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 20px 8px 0;
    margin-bottom: 15px;
    .ui-icon {
      color: $gray-dark;
      transition: $dur $ease;
    }
    &.active {
      background-color: $greenLight;
    }
  }
  .ui-accordion__content {
    margin: 0;
    padding: 0;
  }
}

.wizard-panel-table {
  position: relative;
  margin: 0 10px 0 0;
  .ms-DetailsList {
    @include media-breakpoint-down(sm) {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &.sendings-table {
    padding: 0 10px 0 15px;
  }
  .ms-DetailsRow {
    border-bottom: 1px solid #f0f0f0;
  }
  &.audience-table {
    .ms-FocusZone {
      @include themed() {
        background-color: t($eleventh-combination);
        min-height: 0;
        &:not(:last-of-type) {
          border-bottom: 1px solid #e0e0e0;
        }
      }
    }
  }
  div[role='columnheader'] {
    height: 100%;
  }
  p {
    margin: 0;
  }
  .show-action-bar {
    .action-bar-wrap {
      opacity: 1;
    }
  }
  .hide-action-bar {
    .action-bar-wrap {
      opacity: 0;
    }
  }
  &.add-contact {
    margin: 0 10px;
  }
  .ms-DetailsHeader-cellName {
    font-weight: 400;
    font-size: 12px;
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ms-DetailsHeader {
    height: 32px;
    line-height: 32px;
    padding-top: 0;
    margin-top: 8px;
    @include themed() {
      background-color: t($eleventh-combination);
    }
  }
  .ms-DetailsRow-cell {
    font-weight: 400;
    font-size: 12px;
    @include themed() {
      color: t($second-text-combination);
    }
    &:nth-of-type(2) {
      @include themed() {
        color: t($fourth-text-combination);
      }
    }
    i {
      @include themed() {
        color: t($second-text-combination);
      }
    }
    .is-checked {
      i {
        &.ms-Check-check {
          color: $white;
        }
      }
    }

    .ui-button {
      height: auto;
      min-width: auto;
    }
  }
  .ms-FocusZone {
    @include themed() {
      background-color: t($sixth-combination);
    }
  }

  .ui-label {
    background-color: #d1ebde;
    color: #1c5d3b;
    text-transform: uppercase;
  }
  div[role='columnheader'] {
    @include themed() {
      background-color: t($eleventh-combination);
      color: t($second-text-combination);
    }
  }
  .ms-DetailsRow-fields {
    align-items: center;
  }
  .ui-table__row {
    cursor: pointer;
    &:hover {
      border-top: none;
    }
    @include themed() {
      background-color: t($sixth-combination);
      color: t($second-text-combination);
    }
  }
  .ms-DetailsHeader-cell {
    background-color: $gray-lighten-1;
  }
  .ms-Button {
    &:hover {
      @include themed() {
        color: t($second-text-combination);
      }
      i {
        @include themed() {
          color: t($second-text-combination);
        }
        background-color: transparent;
      }
    }
  }
  .ms-Button--icon {
    &:hover {
      @include themed() {
        background-color: t($second-combination);
      }
    }
  }
  .panel-table-popup-trigger {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ms-Check {
    &::before {
      border-radius: 3px;
      border: 1px solid $dark-1;
    }
    &.is-checked {
      &::before {
        border: 1px solid $purple;
        background: $purple;
      }
    }
    i[data-icon-name='CircleRing'] {
      display: none;
    }
  }

  .ui-table__cell__content {
    @include text-truncate;
  }
}

.panel-table-popup-list {
  .ms-ContextualMenu-list {
    @include themed() {
      background-color: t($seventh-combination);
      color: t($second-text-combination);
    }
  }
  .ms-ContextualMenu-icon {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ms-ContextualMenu-itemText {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  .ms-ContextualMenu-link {
    &:hover {
      @include themed() {
        background-color: t($second-combination);
        color: t($second-text-combination);
      }
    }
  }

  .ms-ContextualMenu-icon.danger {
    color: $red;

    + .ms-ContextualMenu-itemText {
      color: $red;
    }
  }
  [aria-disabled='true'] {
    opacity: 0.6;
  }
}

.wizard-content {
  margin-bottom: 76px;
  padding-left: 20px;
  margin-left: 196px;
}
.sendings-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 9px;
  margin-bottom: 9px;
  min-width: 1024px;
  @include themed() {
    background-color: t($eleventh-combination);
  }
  button {
    margin-right: 12px;
  }

  .ai-content-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
    font-size: 11px;
    cursor: default;
    @include themed() {
      color: t($second-text-combination);
    }
    i {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.report-ai-modal {
  height: 420px;
  width: 100%;
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include themed() {
    color: t($second-text-combination);
  }
  .report-ai-modal-main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .report-ai-modal-footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0 5px 0 5px;
    }
  }

  .message {
    margin: 1rem 0 1rem 0;
  }
  h1 {
    font-size: 20px;
  }

  textarea {
    background-color: #f5f5f5;
    height: 90px;
  }
}

.wizard-search {
  width: 100%;
  .empty-search {
    box-shadow: none;
    @include themed() {
      background-color: t($sixth-combination);
      color: t($second-text-combination);
    }
  }
  .dropdown-segments {
    max-height: 100%;
  }
  .ui-box:first-child {
    width: 100%;
  }
  .ui-input__input {
    @include themed() {
      background-color: t($eleventh-combination);
      color: t($second-text-combination);
    }
    &::placeholder {
      @include themed() {
        color: t($second-text-combination);
        opacity: 0.7;
      }
    }
  }
}

.wizard-arrow-button {
  position: absolute;
  left: 15px;
  top: 5px;
  z-index: 1000;
  .ui-button {
    @include themed() {
      color: t($second-text-combination);
    }
  }
  &:hover {
    .ui-button {
      @include themed() {
        background-color: t($third-combination);
      }
    }
  }
}

.wizard-arrow-button {
  position: absolute;
  left: 15px;
  top: 0;
  z-index: 1000;
}

button {
  &.stagemode__button--active {
    background-color: $purple !important;
  }
}
.empty-saved-search {
  padding-left: 15px;
  @include themed() {
    color: t($second-text-combination);
  }
}
.ms-ContextualMenu {
  @include themed() {
    background-color: t($sixth-combination);
  }
}
