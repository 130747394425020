//----------  variables
//------------------------------------------------------------------------------

$font-default: 'Roboto', Arial, sans-serif;

$bold: 700;
$semi-bold: 600;
$regular: 400;
$light: 300;

$dur-big: 0.8s;
$dur: 0.5s;
$dur-medium: 0.4s;
$dur-small: 0.2s;

$ease: ease;
$ease-out: ease-out;
$ease-in: ease-in;
$ease-in-out: ease-in-out;

// Colors
$white: #fff;
$dark-white: #fdfdff;
$black: #000;
$green: #237b4b;
$light-green: #92c353;
$greenLight: #e7f8d1;
$red: #c50f1f;
$orange: #ffaa44;
$yellow: #f8d22a;
$transparent: transparent;

$dark: #242424;
$dark-1: #292929;
$dark-2: #1f1f1f;
$dark-light: #616161;
$dark-blue: #2c2c2d;
$dark-gray: #1a1a1a;
$dark-brown: #1b1a19;
$dark-gray-light: #595959;
$dark-gray-light-1: #424242;
$dark-gray-light-2: #605e5c;
$dark-gray-light-3: #ccc;

$sliver-light: #ebebeb;
$silver-dark: #d1d1d1;

$gray: #a19f9d;
$gray-light: #d8d8d8;
$gray-lighten: #f0f0f0;
$gray-lighten-1: #f8f8f8;
$gray-lighten-2: #edebe9;
$gray-lighten-3: #f5f5f5;
$gray-lighten-4: #e1dfdd;
$gray-darken: #212121;
$gray-dark: #616161;
$gray-blue-light: #f4f4fc;

// Microsoft Teams color
$purple: #5b5fc7;
$purple-dark: #585a96;
$purple-light: #8b8cc7;
$purple-light-2: #dbdcf0;
$purple-lighten: #f5f6ff;
$blue: #464eb8;
$light-blue: #c8eced;

$colors: #e9c7cd, #fbded7, #f8eacd, #e6eddc;
$reapet: 20;
$purple-light: #8b8cc7;
$purple-lighten: #f5f6ff;
$blue: #464eb8;

$site-colors: (
  'black': $black,
  'white': $white
) !default;

//----------  Bootstrap grid
//------------------------------------------------------------------------------

$enable-grid-classes: true !default;

//----------  Media queries breakpoints - Grid breakpoint
//------------------------------------------------------------------------------

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

//----------  Grid containers
//------------------------------------------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

//----------  Grid columns
//------------------------------------------------------------------------------

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
