@import 'sass/abstracts/variables';

$first-combination: 'first-combination';
$second-combination: 'second-combination';
$third-combination: 'third-combination';
$fourth-combination: 'fourth-combination';
$fifth-combination: 'fifth-combination';
$sixth-combination: 'sixth-combination';
$seventh-combination: 'seventh-combination';
$eighth-combination: 'eighth-combination';
$ninth-combination: 'ninth-combination';
$tenth-combination: 'tenth-combination';
$eleventh-combination: 'eleventh-combination';
$twelve-combination: 'twelve-combination';
$thirteen-combination: 'thirteen-combination';
$fourteenth-combination: 'fourteenth-combination';
$fifteenth-combination: 'fifteenth-combination';
$sixteenth-combination: 'sixteenth-combination';
$seventeen-combination: 'seventeen-combination';
$eighteen-combination: 'eighteen-combination';
$first-text-combination: 'first-text-combination';
$second-text-combination: 'second-text-combination';
$third-text-combination: 'third-text-combination';
$fourth-text-combination: 'fourth-text-combination';
$fifth-text-combination: 'fifth-text-combination';
$sixth-text-combination: 'sixth-text-combination';
$seven-text-combination: 'sixth-text-combination';
$eight-text-combination: 'eight-text-combination';
$nine-text-combination: 'nine-text-combination';
$tenth-text-combination: 'tenth-text-combination';

$first-border-combination: 'first-border-combination';
$second-border-combination: 'second-border-combination';
$third-border-combination: 'third-border-combination';
$fourth-border-combination: 'fourth-border-combination';
$fifth-border-combination: 'fifth-border-combination';
$sixth-border-combination: 'sixth-border-combination';
$seven-border-combination: 'seven-border-combination';

$first-icon-combination: 'first-icon-combination';
$second-icon-combination: 'second-icon-combination';
$third-icon-combination: 'third-icon-combination';
$fourth-icon-combination: 'fourth-icon-combination';

$first-box-shadow-combination: 'first-box-shadow-combination';
$second-box-shadow-combination: 'second-box-shadow-combination';

$first-hover-combination: 'first-hover-combination';
$second-hover-combination: 'second-hover-combination';
$third-hover-combination: 'third-hover-combination';

//themes
$themes: (
  default: (
    first-combination: $white,
    second-combination: $gray-lighten-3,
    third-combination: $gray-lighten,
    fourth-combination: $gray-lighten-3,
    fifth-combination: $gray-lighten,
    sixth-combination: $white,
    seventh-combination: $white,
    eighth-combination: $white,
    ninth-combination: $white,
    tenth-combination: $white,
    eleventh-combination: $gray-lighten-1,
    twelve-combination: $purple-light-2,
    thirteen-combination: $transparent,
    fourteenth-combination: $dark-1,
    fifteenth-combination: $purple-lighten,
    sixteenth-combination: $gray-lighten,
    seventeen-combination: $dark-gray-light-3,
    eighteen-combination: $gray-lighten-2,
    first-text-combination: $dark,
    second-text-combination: $dark,
    third-text-combination: $gray-dark,
    fourth-text-combination: $purple,
    fifth-text-combination: $dark,
    sixth-text-combination: $dark-gray-light,
    seven-text-combination: $dark-gray-light-1,
    eight-text-combination: $dark-light,
    nine-text-combination: $dark-gray-light-2,
    tenth-text-combination: $purple,
    first-border-combination: $white,
    second-border-combination: $silver-dark,
    third-border-combination: $silver-dark,
    fourth-border-combination: $white,
    fifth-border-combination: $silver-dark,
    sixth-border-combination: $gray-lighten-2,
    seven-border-combination: $gray-lighten,
    first-icon-combination: $dark,
    second-icon-combination: $dark,
    third-icon-combination: $dark,
    fourth-icon-combination: $gray-dark,
    first-box-shadow-combination: $transparent,
    first-hover-combination: $gray-blue-light,
    second-hover-combination: $gray-lighten,
    third-hover-combination: $gray-lighten-1
  ),
  dark: (
    first-combination: $black,
    second-combination: $black,
    third-combination: $black,
    fourth-combination: $dark-brown,
    fifth-combination: $dark-2,
    sixth-combination: $dark-1,
    seventh-combination: $dark-2,
    eighth-combination: $dark-blue,
    ninth-combination: $dark-brown,
    tenth-combination: $transparent,
    eleventh-combination: $dark-brown,
    twelve-combination: $white,
    thirteen-combination: $white,
    fourteenth-combination: $white,
    fifteenth-combination: $purple,
    sixteenth-combination: $white,
    seventeen-combination: $dark-2,
    eighteen-combination: $dark-gray-light,
    first-text-combination: $gray,
    second-text-combination: $white,
    third-text-combination: $white,
    fourth-text-combination: $white,
    fifth-text-combination: $gray-lighten-1,
    sixth-text-combination: $white,
    seven-text-combination: $white,
    eight-text-combination: $white,
    nine-text-combination: $white,
    tenth-text-combination: $dark,
    first-border-combination: $gray,
    second-border-combination: $transparent,
    third-border-combination: $dark-gray,
    fourth-border-combination: $dark-blue,
    fifth-border-combination: $dark-gray-light,
    sixth-border-combination: $dark-blue,
    seven-border-combination: $dark-blue,
    first-icon-combination: $gray,
    second-icon-combination: $white,
    third-icon-combination: $gray-lighten-1,
    fourth-icon-combination: $white,
    first-box-shadow-combination: $black,
    first-hover-combination: $dark,
    second-hover-combination: $dark,
    third-hover-combination: $dark-blue
  )
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
